@charset 'utf-8';
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.box {
  background: #041D36;
  width: 100%;
}
.box .content-main {
  margin-left: 1.25rem;
  margin-top: 20px;
  margin-right: 20px;
  background: url('../image/body-bg.png') no-repeat;
  background-size: 100% 100% !important;
  height: 90%;
  border-radius: 15px;
}
.box .content-main .content-top {
  display: inline-block;
  padding-top: 20px;
  padding-left: 20px;
}
.box .content-main .content-top .ant-select-enabled {
  width: 15.5rem !important;
}
.box .content-main .content-top .ant-row.ant-form-item {
  display: inline-block;
}
.box .content-main .content-top .ant-row.ant-form-item span.key-words {
  color: #00C1DE;
  margin-left: 20px;
}
.box .content-main .content-top .ant-row.ant-form-item input.ant-input {
  width: 14.5rem;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
  margin-left: 10px;
}
.box .content-main .content-top .ant-row.ant-form-item input::-webkit-input-placeholder {
  color: #057797;
}
.box .content-main .content-top .ant-row.ant-form-item .ant-select .ant-select-selection {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.box .content-main .content-top .ant-row.ant-form-item .ant-select .ant-select-selection .ant-select-arrow {
  color: #6EF9FF;
}
.box .content-main .content-top .left {
  float: left;
  width: 37%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box .content-main .content-top .btn-group {
  display: inline-block;
  margin-left: 50px;
  line-height: 40px;
}
.box .content-main .content-top .btn-group .search-btn {
  position: relative;
  width: 6.875rem;
  height: 30px;
  background: transparent url('../image/btn.png') no-repeat center;
  background-size: 100% 100%;
  border: none;
  outline: none;
  color: #6EF9FF;
  margin-right: 20px;
}
.box .content-main .content-top .btn-group .search-btn:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.box .content-main .content-top .btn-group .search-btn:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.box .content-main .content-top .btn-group .delete-btn {
  color: #F24235;
  background: url('../image/delete-btn.png') no-repeat;
  background-size: 100% 100%;
}
.box .content-main .content-top .btn-group .delete-btn:hover {
  background: url('../image/del_sel.png') no-repeat;
  background-size: 100% 100%;
}
.box .content-main .content-top .btn-group .delete-btn:active {
  background: url('../image/del_pre.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.box .content-main .divider {
  width: 97.5%;
  height: 2px;
  background: #074962;
  margin-left: 22px;
}
.box .content-main .content-table {
  margin-top: -5px;
}
.box .content-main .content-table .ant-table-content {
  margin-left: 22px;
  margin-top: 20px;
  width: 100%;
}
.box .content-main .content-table .ant-table-content .ant-table-placeholder {
  background: transparent !important;
}
.box .content-main .content-table .ant-table-content .ant-table-placeholder .ant-empty-description {
  color: #6ef9ff;
}
.box .content-main .content-table .ant-table-content table {
  font-size: 1rem !important;
}
.box .content-main .content-table .ant-table-content table thead th {
  background: rgba(0, 193, 222, 0.14);
  text-align: center !important;
}
.box .content-main .content-table .ant-table-content table thead th span.ant-table-header-column div span.ant-table-column-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00c1de;
}
.box .content-main .content-table .ant-table-content table thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00C4C8;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.box .content-main .content-table .ant-table-content table thead th span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .content-main .content-table .ant-table-content table .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.box .content-main .content-table .ant-table-content table tbody .ant-table-row {
  background: rgba(4, 29, 54, 0.5) !important;
}
.box .content-main .content-table .ant-table-content table tbody td {
  border: none !important;
  text-align: center !important;
  padding: 20px 0px !important;
  position: relative;
  color: #00C1DE;
}
.box .content-main .content-table .ant-table-content table tbody td .ant-checkbox-wrapper .ant-checkbox span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .content-main .content-table .ant-table-content table tbody td .ant-checkbox-wrapper-checked .ant-checkbox-checked span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: #00C4C8;
}
.box .content-main .content-table .ant-table-content table tbody td .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #05213C;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.box .content-main .content-table .ant-table-content table tbody td a.delete i {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../image/shanchu.png') no-repeat;
  background-size: 100% 100%;
}
.box .content-main .content-table .ant-table-content table tbody td a.amend i {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('../image/bianji.png') no-repeat;
}
.box .content-main .content-table .ant-table-content table tbody td .ant-divider,
.box .content-main .content-table .ant-table-content table tbody td .ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 2px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  background: #08637B;
}
.box .content-main .content-table .ant-table-content table tbody td a.edit i {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../image/jilu.png') no-repeat !important;
}
.box .content-main .content-table .ant-table-content table tbody td tbody tr .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #05213C !important;
}
.box .content-main .content-table .ant-table-content table tbody td .ant-tag {
  border: none !important;
}
.box .content-main .content-table .ant-table-content table tbody tr {
  line-height: 0;
}
.box .content-main .content-table .ant-table-content tbody tr.even-bg {
  background: #05213C !important;
}
.box .content-main .content-table .ant-table-content tbody tr.odd-bg {
  background: #082B4A !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination {
  float: left !important;
  margin-left: 23px;
  margin-top: 1.5%;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-total-text {
  display: none !important;
  height: 25px !important;
  line-height: 22px !important;
  margin-left: 15px !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-prev {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
  border-radius: 0px !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-prev svg {
  vertical-align: 0.3em !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-options {
  display: none !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-jump-prev {
  float: left !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-jump-next {
  float: left !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-prev a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-item {
  height: 28px;
  background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(70, 255, 255, 0.5);
  border-radius: 1px;
  float: left;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-item:hover {
  border: none !important;
  background: #00C4C8 !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-item a {
  margin: 0 !important;
  color: #6EF9FF;
  line-height: 28px;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: #00C4C8 !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-next {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  border-radius: 0px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-next a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .content-main .content-table .ant-table-wrapper .ant-pagination .ant-pagination-next a svg {
  vertical-align: 0.3em !important;
}
.add-gather .ant-modal-content {
  width: 500px;
  height: 550px;
  margin-left: 15%;
  margin-top: 20%;
  background: url('../image/login/kuang.png') no-repeat;
  background-size: 100% 68%;
}
.add-gather .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.add-gather .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 1.125rem;
}
.add-gather .ant-modal-content .ant-modal-body {
  background: #022446 !important;
  margin: 0 22px 10px 22px;
  height: 250px;
  overflow: auto;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left {
  margin-top: 50px;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col {
  display: inline-block;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col label {
  color: #00C1DE;
  font-size: 1.1429rem;
  width: 7.8571rem;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control {
  width: 20rem;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-input {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-select-selection {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
  width: 15rem !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-select-selection .ant-select-arrow {
  color: #6EF9FF;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-form-explain {
  position: absolute;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-form-item-children i.select-btn {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('../image/add.png') no-repeat;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .code {
  margin-bottom: 14px !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .code label {
  color: #6EF9FF;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .code .ant-input {
  width: 240px !important;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout {
  height: 120px;
  background: transparent;
  padding: 0 !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content {
  background: transparent  !important;
  padding: 0 !important;
  min-height: 0px !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col {
  width: 110px;
  display: inline-block;
  position: absolute;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .ant-form-item-control {
  display: inline;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .ant-form-item-control .ant-input {
  width: 240px;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col label {
  color: #00C1DE;
  font-size: 1rem;
  /* display: inline-block; */
  width: 6.875rem;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select {
  margin-left: 9%;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col {
  display: inline-block;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col label {
  color: #00C1DE;
  font-size: 1rem;
  width: 6.875rem;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col .ant-form-item-control {
  width: 280px;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col .ant-form-item-control .ant-input {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
  width: 210px;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col .ant-form-item-control .ant-form-explain {
  position: absolute;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .user-select .ant-col .ant-form-item-control .ant-form-item-children i.select-btn {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('../image/add.png') no-repeat;
  margin-left: 5px;
  vertical-align: middle;
}
.add-gather .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  position: relative;
}
.add-gather .ant-modal-content .ant-modal-footer .ant-btn {
  background: url('../image/login/anniu.png') no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 45px;
}
.add-gather .ant-modal-content .ant-modal-footer .ant-btn span {
  color: #7DFAFF;
}
.add-gather .ant-modal-content .ant-modal-footer .cancel {
  position: absolute;
  left: 100px;
}
.add-gather .ant-modal-content .ant-modal-footer .cancel:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.add-gather .ant-modal-content .ant-modal-footer .cancel:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.add-gather .ant-modal-content .ant-modal-footer .confirm {
  position: absolute;
  right: 50px;
}
.add-gather .ant-modal-content .ant-modal-footer .confirm:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.add-gather .ant-modal-content .ant-modal-footer .confirm:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.add-gather .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -10px;
  margin-right: -10px;
}
.add-gather .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: none;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff !important;
}
.add-gather .ant-modal-content .ant-modal-close:hover {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi1.png') no-repeat;
  background-size: 100% 100%;
}
.add-gather .ant-modal-content .ant-modal-close:active {
  background: url(../image/guanbi2.png) no-repeat;
  background-size: 100% 100%;
}
.add-gather .ant-modal-body::-webkit-scrollbar {
  width: 10px;
}
.add-gather .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #043853;
}
.add-gather .ant-modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 10px 10px 0 #052E49;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .left .ant-col .ant-form-item-control .ant-select-selection {
  width: 17.1429rem;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-error .ant-form-explain {
  color: #f5222d;
  position: absolute;
  width: 17.8571rem;
  margin-left: 110px;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-error .ant-form-explain.show-help-leave {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-error .ant-form-explain.show-help-enter {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-error .ant-form-explain.show-help-leave-active {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-error .ant-form-explain.show-help-enter-active {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-success .ant-form-explain.show-help-leave {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-success .ant-form-explain.show-help-enter {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-success .ant-form-explain.show-help-leave-active {
  display: none !important;
}
.add-gather .ant-modal-content .ant-modal-body .ant-form .ant-layout .ant-layout-content .ant-col .has-success .ant-form-explain.show-help-enter-active {
  display: none !important;
}
.show-gather .ant-modal-content {
  width: 555px;
  height: 600px;
  margin-left: 15%;
  margin-top: 10%;
  background: url('../image/login/kuang.png') no-repeat;
  background-size: 100% 100%;
}
.show-gather .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.show-gather .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 1.125rem;
}
.show-gather .ant-modal-content .ant-modal-body {
  background: rgba(2, 36, 70, 0.7) !important;
  margin: 0 22px 10px 22px;
  height: 465px;
  overflow: auto;
}
.show-gather .ant-modal-content .ant-modal-body .dev-detail p {
  color: #6EF9FF;
}
.show-gather .ant-modal-content .ant-modal-body .dev-use {
  margin-top: 20%;
}
.show-gather .ant-modal-content .ant-modal-body .dev-use .pit-pie {
  display: inline-block;
  position: relative;
}
.show-gather .ant-modal-content .ant-modal-body .dev-use .pit-pie .title {
  color: #6EF9FF;
  text-align: center;
}
.show-gather .ant-modal-content .ant-modal-body .dev-use .pit-pie .decription {
  position: absolute;
  left: 18%;
}
.show-gather .ant-modal-content .ant-modal-body .dev-use .pit-pie .decription p {
  color: #6EF9FF;
}
.show-gather .ant-modal-content .ant-modal-body .ant-form .ant-form-item label {
  color: #00C1DE;
  font-size: 1rem;
}
.show-gather .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.show-gather .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.show-gather .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  padding: 0 !important;
  position: relative;
  margin-top: 20px;
}
.show-gather .ant-modal-content .ant-modal-footer .close-btn {
  background: url('../image/login/anniu.png') no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 45px;
  text-align: center;
  color: #6EF9FF;
  position: absolute;
  right: -21px;
  top: 0;
}
.show-gather .ant-modal-content .ant-modal-footer .close-btn:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.show-gather .ant-modal-content .ant-modal-footer .close-btn:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.show-gather .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -10px;
  margin-right: -10px;
}
.show-gather .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: none;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff !important;
}
.show-gather .ant-modal-content .ant-modal-close:hover {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi1.png') no-repeat;
  background-size: 100% 100%;
}
.show-gather .ant-modal-content .ant-modal-close:active {
  background: url(../image/guanbi2.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.show-gather .ant-modal-body::-webkit-scrollbar {
  width: 10px;
}
.show-gather .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #043853;
}
.show-gather .ant-modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 10px 10px 0 #052E49;
}
.amend-gather .ant-modal-content {
  width: 500px;
  height: 470px;
  margin-left: 15%;
  margin-top: 20%;
  background: url('../image/login/kuang.png') no-repeat;
  background-size: 100% 68%;
}
.amend-gather .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.amend-gather .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 1.125rem;
}
.amend-gather .ant-modal-content .ant-modal-body {
  background: rgba(2, 36, 70, 0.7) !important;
  margin: 0 22px 10px 22px;
  height: 190px;
  overflow: auto;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout {
  background: transparent;
  padding: 0 !important;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout .ant-layout-content {
  background: transparent  !important;
  padding: 0 !important;
  min-height: 0px !important;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout .ant-layout-content .ant-col {
  width: 110px;
  display: inline;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout .ant-layout-content .ant-col .ant-form-item-control {
  display: inline;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout .ant-layout-content .ant-col .ant-form-item-control .ant-input {
  width: 240px;
}
.amend-gather .ant-modal-content .ant-modal-body .ant-layout .ant-layout-content .ant-col label {
  color: #00C1DE;
  font-size: 1rem;
  /* display: inline-block; */
  width: 110px;
}
.amend-gather .ant-modal-content .ant-modal-body .left {
  margin-top: 10px;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col {
  display: inline-block;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col label {
  color: #00C1DE;
  font-size: 1rem;
  display: inline-block;
  width: 110px;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control {
  width: 280px;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-input {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
  width: 15rem;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-select-selection {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-select-selection .ant-select-arrow {
  color: #6EF9FF;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-form-explain {
  position: absolute;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-form-item-children span.key-words {
  color: #6EF9FF !important;
  margin-left: 35px;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col #deptId {
  position: absolute;
  left: 80px;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-form-item-children i.amend-btn {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('../image/add.png') no-repeat;
  margin-left: 5px;
  vertical-align: middle;
  position: absolute;
  left: 320px;
}
.amend-gather .ant-modal-content .ant-modal-body .user-select label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.amend-gather .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  position: relative;
}
.amend-gather .ant-modal-content .ant-modal-footer .ant-btn {
  background: url('../image/btn.png') no-repeat center;
  border: none;
  outline: none;
  background-size: 100% 100%;
  height: 30px;
  width: 110px;
  margin-right: 45px;
}
.amend-gather .ant-modal-content .ant-modal-footer .ant-btn span {
  color: #7DFAFF;
}
.amend-gather .ant-modal-content .ant-modal-footer .cancel {
  position: absolute;
  left: 100px;
}
.amend-gather .ant-modal-content .ant-modal-footer .cancel:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.amend-gather .ant-modal-content .ant-modal-footer .cancel:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.amend-gather .ant-modal-content .ant-modal-footer .confirm {
  position: absolute;
  right: 50px;
}
.amend-gather .ant-modal-content .ant-modal-footer .confirm:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.amend-gather .ant-modal-content .ant-modal-footer .confirm:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.amend-gather .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -10px;
  margin-right: -10px;
}
.amend-gather .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: none;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff !important;
}
.amend-gather .ant-modal-content .ant-modal-close:hover {
  width: 36px;
  height: 36px;
  background: url('../image/guanbi1.png') no-repeat;
  background-size: 100% 100%;
}
.amend-gather .ant-modal-content .ant-modal-close:active {
  background: url(../image/guanbi2.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.amend-gather .ant-modal-body::-webkit-scrollbar {
  width: 10px;
}
.amend-gather .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #043853;
}
.amend-gather .ant-modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 10px 10px 0 #052E49;
}
.amend-gather .ant-modal-content .ant-modal-body .left .ant-col .ant-form-item-control .ant-input {
  position: absolute;
  left: 80px;
}
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .box .content-main {
    height: 140%;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .box .content-main {
    height: 108%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .box .content-main {
    height: 108%;
  }
  .box .content-main .content-top .ant-input {
    width: 13.5rem !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .box .content-main {
    height: 118%;
  }
}
