.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('../../image/login/bg.png') no-repeat 50%;
}
.login-page .login-box {
  height: 100%;
}
.login-page .login-box .top-header {
  width: 100%;
  height: 6.5vh;
  font-size: 0.24em;
  padding: 0 1.5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(13, 208, 252, 0.1);
}
.login-page .login-box .top-header .top-left {
  display: inline-block;
  line-height: 60px;
  margin-left: 25px;
}
.login-page .login-box .top-header .top-left .top-title {
  font-size: 1.5rem;
  color: #fff;
  font-family: Microsoft YaHei;
}
.login-page .login-box .top-header .top-right {
  margin-right: 44px;
  display: inline-block;
  color: #fff;
  float: right;
  line-height: 60px;
  font-size: 1.125rem;
  font-family: Microsoft YaHei;
}
.login-page .login-box .top-header .top-right .help {
  display: inline-block;
  margin-right: 20px;
  visibility: hidden;
}
.login-page .login-box .top-header .top-right .help .help-icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  background: url(../../image/help/help.png) no-repeat;
  vertical-align: middle;
  margin-right: 5px;
}
.login-page .login-box .top-header .top-right .help .doc-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('../../image/help/word.png') no-repeat;
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 5px;
}
.login-page .login-box .top-header .top-right .help .video-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('../../image/help/video.png') no-repeat;
  vertical-align: middle;
  margin-right: 5px;
}
.login-page .login-box .top-header .top-right .date-time {
  display: inline-block;
}
.login-page .login-box .login-content {
  width: 100%;
  height: calc(100% - 6.5vh);
}
.login-page .login-box .login-content .login-form {
  width: 15%;
  margin-top: 1.5rem;
  margin-left: 1%;
  height: 90%;
  background: rgba(0, 64, 125, 0.5);
  float: left;
}
.login-page .login-box .login-content .login-form .user-message {
  padding-top: 8rem;
}
.login-page .login-box .login-content .login-form .user-message .user-avatar i {
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  background: url('../../image/login/noLogin.png') no-repeat;
  margin: 0 auto;
  background-size: cover;
}
.login-page .login-box .login-content .login-form .user-message .login-title {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: center;
}
.login-page .login-box .login-content .login-form .ant-form {
  margin-top: 12%;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item {
  width: 85%;
  margin: 20px  auto;
  position: relative;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .ant-input {
  height: 50px;
  background: rgba(14, 21, 44, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: #fff;
  padding-left: 50px;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item input::-webkit-input-placeholder {
  margin-left: 20px;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .icon-left {
  display: block;
  width: 22px;
  height: 24px;
  position: absolute;
  top: -3px;
  left: 14px;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .icon-right {
  display: block;
  width: 22px;
  height: 24px;
  position: absolute;
  top: -3px;
  right: 14px;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .user-left {
  background: rgba(14, 21, 44, 0.5) url('../../image/login/user.png') no-repeat;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .user-right {
  background: rgba(14, 21, 44, 0.5) url('../../image/login/cancel.png') no-repeat;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .pass-left {
  background: rgba(14, 21, 44, 0.5) url('../../image/login/password.png') no-repeat;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .pass-right {
  background: rgba(14, 21, 44, 0.5) url('../../image/login/cancel.png') no-repeat;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .verify-left {
  background: rgba(14, 21, 44, 0.5) url('../../image/login/verify.png') no-repeat;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .verify-input {
  width: 9rem;
  display: inline-block;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .verify-img {
  width: 5rem;
  display: inline-block;
  margin-left: 0.65rem;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .auto-login {
  height: 1rem !important;
  width: 1rem !important;
  padding-left: 0px !important;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #00B4DA;
  outline: none;
  vertical-align: middle;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item input[type="checkbox"]:checked {
  background: url('../../image/login/checked.png') no-repeat;
  outline: none;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .auto-text {
  color: #00b4da;
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  margin-left: 10px;
  line-height: 30px;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .submit-btn {
  width: 100%;
  height: 50px;
  background: rgba(0, 180, 218, 0.1);
  border: 1px solid #00B4DA;
  border-radius: 3px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00f2f6;
}
.login-page .login-box .login-content .login-form .ant-form .ant-form-item .submit-btn:hover {
  background: #00B4DA;
  color: #fff;
}
.login-page .login-box .login-content .nav-list {
  width: 84%;
  display: flex;
  position: relative;
  height: 100%;
}
.login-page .login-box .login-content .nav-list .left-nav-content {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 50%;
  height: 70%;
}
.login-page .login-box .login-content .nav-list .left-nav-content .content-title {
  font-size: 1.25rem;
  padding-left: 1vw;
  position: relative;
  color: #fff;
  border-left: 4px solid #00e8fb;
}
.login-page .login-box .login-content .nav-list .right-nav-content {
  position: absolute;
  top: 10%;
  right: 3%;
  width: 40%;
  height: 70%;
}
.login-page .login-box .login-content .nav-list .right-nav-content .content-title {
  font-size: 1.25rem;
  padding-left: 1vw;
  color: #fff;
  border-left: 4px solid #00e8fb;
  position: relative;
}
.login-page .login-box .login-content .nav-list .right-nav-content .content-title .list2-title,
.login-page .login-box .login-content .nav-list .right-nav-content .content-title .module-icon,
.login-page .login-box .login-content .nav-list .right-nav-content .content-title .module-text {
  display: inline-block;
}
.login-page .login-box .login-content .nav-list .right-nav-content .content-title .module-text {
  position: absolute;
  right: 0;
}
.login-page .login-box .login-content .nav-list .right-nav-content .content-title i {
  display: inline-block;
  background: url('../../image/login/gl.png') no-repeat center;
  vertical-align: middle;
  width: 1.25rem;
  height: 2.25rem;
  position: absolute;
  right: 5.625rem;
}
.login-page .login-box .login-content .nav-list .main-nav-content,
.login-page .login-box .login-content .nav-list .extra-nav-content {
  width: 100%;
  height: 100%;
  margin-top: 2.2vh;
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules {
  color: #FFFFFF;
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules div,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules div {
  text-align: center;
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules div p,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules div p {
  font-size: 0.18em;
  margin-top: 0.46vh;
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules div .icon,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules div .icon {
  display: block;
  width: 4.8vw;
  height: 4.8vw;
  transition: all 0.6s;
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules:hover .icon,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules:hover .icon {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.login-page .login-box .login-content .nav-list .main-nav-content .modules:hover img,
.login-page .login-box .login-content .nav-list .extra-nav-content .modules:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.login-page .login-box .login-content .nav-list .main-nav-content .nav-bg-show,
.login-page .login-box .login-content .nav-list .extra-nav-content .nav-bg-show {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.login-page .login-box .login-content .nav-list .main-nav-content .nav-bg-show img,
.login-page .login-box .login-content .nav-list .extra-nav-content .nav-bg-show img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  transition: all 0.6s;
}
.login-page .login-box .login-content .nav-list .main-nav-content .nav-bg-show p,
.login-page .login-box .login-content .nav-list .extra-nav-content .nav-bg-show p {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4vh;
  line-height: 4vh;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: left;
  padding: 0 1vh;
  margin: 0;
}
.login-page .login-box .login-content .nav-list .list1 {
  width: 100%;
  height: calc(100% - 5.5vh);
}
.login-page .login-box .login-content .nav-list .list1 .modules {
  color: #fff;
  float: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page .login-box .login-content .nav-list .list1 .modules:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.login-page .login-box .login-content .nav-list .list1 .modules:first-child .title,
.login-page .login-box .login-content .nav-list .list1 .modules:last-child .title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4vh;
  line-height: 4vh;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: left;
  padding: 0 1vh;
  margin: 0;
}
.login-page .login-box .login-content .nav-list .list1 .modules i {
  display: block;
  position: absolute;
  width: 5vh;
  height: 5vh;
}
.login-page .login-box .login-content .nav-list .list1 .modules:first-child {
  width: calc(63% - 0.5vw);
  height: calc(60% - 0.51vw);
  margin: 0 0.5vw 0.5vw 0;
  background: url('../../image/login/gis.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(2) {
  width: 37%;
  height: calc(60% - 0.5vw);
  margin-bottom: 0.5vw;
  background: url('../../image/login/1.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(2) i {
  background: url('../../image/login/jkgl\ .png') center center / 100% 100% no-repeat;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(2) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(3),
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(4) {
  width: calc(31.5% - 0.5vw);
  height: 40%;
  margin-right: 0.5vw;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(3) {
  background: url('../../image/login/1.png') no-repeat;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(3) i {
  background: url('../../image/login/wjgl.png') center center no-repeat;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(3) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(4) {
  background: url('../../image/login/3.png') no-repeat;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(4) i {
  background: url('../../image/login/gjjl.png') center center no-repeat;
}
.login-page .login-box .login-content .nav-list .list1 .modules:nth-child(4) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list1 .modules:last-child {
  width: 37%;
  height: 40%;
  background: url('../../image/login/tongji.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 {
  width: 100%;
  height: calc(100% - 5.5vh);
}
.login-page .login-box .login-content .nav-list .list2 .part1 i,
.login-page .login-box .login-content .nav-list .list2 .part2 i,
.login-page .login-box .login-content .nav-list .list2 .part3 i,
.login-page .login-box .login-content .nav-list .list2 .part4 i,
.login-page .login-box .login-content .nav-list .list2 .part5 i {
  display: block;
  width: 80px;
  height: 80px;
  margin-top: 40%;
  margin-left: 36%;
}
.login-page .login-box .login-content .nav-list .list2 .part1 {
  width: calc(50% - 0.5vw);
  height: calc(60% - 0.51vw);
  margin: 0 0.5vw 0.5vw 0;
}
.login-page .login-box .login-content .nav-list .list2 .part2,
.login-page .login-box .login-content .nav-list .list2 .part3 {
  width: 50%;
  height: calc(30% - 0.5vw);
  margin-bottom: 0.5vw;
}
.login-page .login-box .login-content .nav-list .list2 .part4,
.login-page .login-box .login-content .nav-list .list2 .part5 {
  width: calc(50% - 0.25vw);
  height: 40%;
  margin-right: 0.25vw;
}
.login-page .login-box .login-content .nav-list .list2 .modules {
  color: #fff;
  position: relative;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
}
.login-page .login-box .login-content .nav-list .list2 .modules i {
  display: block;
  position: absolute;
  width: 6vh;
  height: 6vh;
}
.login-page .login-box .login-content .nav-list .list2 .modules:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child {
  margin: 0 0.5vw 0.5vw 0;
  width: calc(50% - 0.5vw);
  height: calc(60% - 0.51vw);
  background: url('../../image/login/4.png') no-repeat;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child .title {
  margin-top: 9vh;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-dept {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-das {
  background: url('../../image/login/caijizhan.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-group {
  background: url('../../image/login/thzgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-dev {
  background: url('../../image/login/zdgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-oplog {
  background: url('../../image/login/wjgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:first-child i.menu-data-statistics {
  background: url('../../image/datastatistics.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) {
  width: 50%;
  height: calc(30% - 0.5vw);
  margin-bottom: 0.5vw;
  background: url('../../image/login/1.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i {
  background: url('../../image/login/caijizhan.png') no-repeat;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i.menu-dept {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i.menu-das {
  background: url('../../image/login/caijizhan.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i.menu-group {
  background: url('../../image/login/thzgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i.menu-dev {
  background: url('../../image/login/zdgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) i.menu-oplog {
  background: url('../../image/login/wjgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(2) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) {
  width: 50%;
  height: calc(30% - 0.5vw);
  margin-bottom: 0.5vw;
  background: url('../../image/login/1.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i {
  background: url('../../image/login/zdgl.png') center center / 100% 100% no-repeat;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i.menu-dept {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i.menu-das {
  background: url('../../image/login/caijizhan.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i.menu-group {
  background: url('../../image/login/thzgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i.menu-dev {
  background: url('../../image/login/zdgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) i.menu-oplog {
  background: url('../../image/login/wjgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(3) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) {
  width: calc(50% - 0.5vw);
  height: 40%;
  margin-right: 0.5vw;
  background: url('../../image/login/1.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i {
  background: url('../../image/login/thzgl.png') center center / 100% 100% no-repeat;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i.menu-dept {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i.menu-das {
  background: url('../../image/login/caijizhan.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i.menu-group {
  background: url('../../image/login/thzgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i.menu-dev {
  background: url('../../image/login/zdgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) i.menu-oplog {
  background: url('../../image/login/wjgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(4) .title {
  margin-top: 8.46vh;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) {
  width: 50%;
  height: 40%;
  margin-bottom: 0.5vw;
  background: url('../../image/login/1.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i {
  width: 8vh;
  height: 8vh;
  background: url('../../image/login/tianjia.png') no-repeat center;
  background-size: 100% 100%;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i.menu-dept {
  background: url('../../image/login/bmgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i.menu-das {
  background: url('../../image/login/caijizhan.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i.menu-group {
  background: url('../../image/login/thzgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i.menu-dev {
  background: url('../../image/login/zdgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) i.menu-oplog {
  background: url('../../image/login/wjgl.png') no-repeat center;
}
.login-page .login-box .login-content .nav-list .list2 .modules:nth-child(5) .title {
  margin-top: 8.46vh;
}
