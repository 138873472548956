@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
}
div,
p,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
body {
  padding: 0;
  margin: 0;
  font-weight: normal;
}
ul,
li,
ol,
dd,
dt {
  list-style: none;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
input {
  padding: 0 6px;
}
input,
button {
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
textarea {
  padding: 6px;
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  resize: none;
  overflow: auto;
}
button {
  cursor: pointer;
  outline: none;
}
:before,
:after,
* {
  box-sizing: border-box;
}
/* 为了避免未经处理的原始模板内容在页面载入时在页面中一闪而过  */
.ms-controller,
.ms-important,
[ms-controller],
[ms-important] {
  visibility: hidden;
}
body {
  color: #333;
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow: hidden;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
/* 针对IE7 hack，触发IE7的haslayout，以清除浮动 */
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
/*滚动条样式*/
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #dedede;
}
::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a2a2a2;
}
::-webkit-scrollbar-thumb:active {
  background-color: #a2a2a2;
}
/*IE滚动条样式*/
/*body {
    scrollbar-face-color:#434b56;
    scrollbar-track-color: #22262f;
    scrollbar-darkshadow-color:#22262f;
    scrollbar-shadow-color: #434b56;
    scrollbar-arrow-color: #434b56;
}*/
/*文字不被选中*/
.unselectable {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  -o-user-select: none;
  /*早期浏览器*/
  user-select: none;
}
.unselectable:after {
  content: attr(data-content);
}
input::-ms-clear {
  display: none;
}
::-ms-clear,
::-ms-reveal {
  display: none;
}
input {
  -webkit-appearance: none;
  -o-appearance: none;
}
