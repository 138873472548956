@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
.monitor-tree {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transform: scale(1);
  transform: scale(1);
  min-height: 610px;
  margin-left: 40px;
  margin-top: 10px;
  min-width: 90%;
}
.monitor-tree .dept-name span {
  color: #a9f8ff;
}
.monitor-tree span {
  color: #848484;
  font-size: 16px;
  line-height: 40px;
}
.monitor-tree li {
  line-height: 36px;
  padding: 3px;
}
.monitor-tree li span.button.chk {
  position: relative;
  margin-left: 5px;
  z-index: 1;
}
.monitor-tree li ul {
  padding: 0 0 0 10px;
}
.monitor-tree li a > span:not(button) {
  max-width: 140px;
  width: 60%;
  vertical-align: middle;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-indent: 0;
}
.monitor-tree li a {
  height: 36px;
  line-height: 36px;
  width: 93%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 15px;
  display: inline-block;
  margin-left: -15px;
  padding-top: 0;
}
.monitor-tree li a:hover {
  border: none;
  height: 36px;
  line-height: 36px;
  background-color: #043551;
  text-decoration: none;
  z-index: -1;
  opacity: 0.8;
}
.monitor-tree li a.curSelectedNode {
  border: none;
  height: 36px;
  line-height: 36px;
  background-color: #043551;
  z-index: -1;
}
.monitor-tree li a.idle .node_name {
  color: #a9f8ff !important;
}
.monitor-tree li a.working .node_name {
  color: #a9f8ff !important;
}
.monitor-tree li a.offline .node_name {
  color: #848484 !important;
}
#monitor-tree .ant-tree li .ant-tree-node-content-wrapper:hover::before {
  background: #043551;
  opacity: 0.7;
  height: 36px;
}
#monitor-tree .ant-tree li:hover > .ant-tree-node-content-wrapper .iconlist .more-operate {
  visibility: visible;
}
#monitor-tree .ant-tree li:hover > .ant-tree-node-content-wrapper .iconlist .play-test {
  visibility: visible;
}
#monitor-tree .ant-tree li {
  min-width: 290px;
}
#monitor-tree .ant-tree li .ant-tree-node-content-wrapper {
  position: relative;
  height: 36px;
}
#monitor-tree .ant-tree li .ant-tree-node-content-wrapper > .ant-tree-title > span > .leafname {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#monitor-tree .ant-tree li .ant-tree-switcher {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
}
#monitor-tree .ant-tree li .ant-tree-title {
  line-height: 36px;
}
#monitor-tree .ant-tree li .iconlist {
  position: relative;
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#monitor-tree .ant-tree li .iconlist .more-operate {
  position: fixed;
  left: 210px;
  visibility: hidden;
}
#monitor-tree .ant-tree li .iconlist .bofang {
  left: 220px;
  position: fixed;
  visibility: hidden;
}
#monitor-tree .ant-tree li .iconlist .play-test {
  left: 210px;
  position: fixed;
  visibility: hidden;
}
#monitor-tree .ant-tree li .iconlist .iconcancelcollect {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+nhxg7wAAANNJREFUOI2lkTtuAkEQBYuPjERox1h2RmjfBU7hbA/ABZBMyiFwSswNICThBJY2c1wE25ZmhzXs4ic9tabndUnTg8oV99Rl1D9z1wCocyvN74WM1FNATnHuDCmsq+gKeVLLDFJG/yLfU4fAFHhL/A48cqkS2AOHxEfUtf/TGnWgbu4EbNTB77se1G1HwDbmaosdq7uWgF3kG39n0hIySef62fabfqRJtVwOeWkJqeVuQX6Ar6itIa9Rv4EF8AzMoi6in+YqZYv9VD/SzWcex/0q7Z8B3+SNVhgRn+MAAAAASUVORK5CYII=);
}
#monitor-tree .ant-tree li .iconlist .leafname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 24px;
}
#monitor-tree .ant-tree li > .ant-tree-node-selected::before {
  background: #043551 !important;
  opacity: 0.7;
  height: 36px;
}
#monitor-tree .ant-tree .ant-tree-child-tree li {
  margin: 2px 0;
  line-height: 36px;
}
#monitor-tree .ant-tree .ant-tree-child-tree li .ant-tree-node-content-wrapper::before {
  display: inline-block;
  transition: none;
}
#monitor-tree .ant-tree .ant-tree-child-tree li:hover .ant-tree-node-selected::before {
  background: #043551;
  opacity: 0.7;
  height: 36px;
}
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-node-content-wrapper:hover::before {
  background: #043551;
  opacity: 0.7;
  height: 36px;
}
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-treenode-selected .ant-tree-iconEle {
  margin: auto 0;
}
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-treenode-selected > .ant-tree-node-selected::before {
  background: #043551 !important;
  opacity: 0.7;
  height: 36px;
}
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-treenode-selected .ant-tree-node-content-wrapper,
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-treenode-selected .ant-tree-node-content-wrapper-normal,
#monitor-tree .ant-tree .ant-tree-child-tree .ant-tree-treenode-selected .ant-tree-node-selected:hover {
  background: none;
}
.monitor-video {
  overflow-x: auto;
  box-sizing: border-box;
}
.terminal-wrap {
  overflow-x: scroll;
  height: 700px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.terminal-wrap .search-box li {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
}
.terminal-wrap .search-box li .search-res {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.terminal-wrap .search-box li .bofang-btn {
  position: fixed;
  margin-top: 5px;
  left: 240px;
}
.terminal-wrap .search-box li .oper-btn-group {
  position: fixed;
  margin-top: 5px;
  left: 240px;
}
.left-nav {
  z-index: 10;
}
