@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
/* 数字字体 */
@font-face {
  font-family: myfont;
  src: url(../../css/butter.ttf), url(../../css/butter.eot);
}
.count-wrap {
  width: 100%;
  height: 100%;
}
.count-wrap .select-wrap {
  margin-top: 25px;
  width: 100%;
  height: 27px;
  background: url(../../image/count-img/headBack.png) no-repeat;
  line-height: 27px;
}
.count-wrap .select-wrap .ant-form {
  position: absolute;
  top: 73px;
  width: 495px;
  height: 27px;
  line-height: 27px;
  margin: 0 36.3%;
}
.count-wrap .select-wrap .ant-form .ant-row {
  margin-right: 5px;
  display: inline-block;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-select-selection {
  background: url(../../image/count-img/areaBack.png) no-repeat !important;
  border: none;
  color: #87EFFF;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-select-arrow .ant-select-arrow-icon {
  padding-right: 15px;
  background: url(../../image/count-img/xiala.png) no-repeat;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-range-picker-input {
  width: 48%;
  padding: 0 !important;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker-clear {
  color: rgba(0, 0, 0, 0.45) !important;
  background: #00Efff !important;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker {
  width: 0px !important;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker .ant-calendar-range-picker-separator {
  color: #87EFFF;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker .ant-input {
  width: 286px;
  background: url(../../image/count-img/dateBack.png) no-repeat !important;
  border: none;
  color: #87EFFF;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker .ant-input input::-webkit-input-placeholder {
  color: #87EFFF;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker .ant-input input:-ms-input-placeholder {
  color: #87EFFF;
}
.count-wrap .select-wrap .ant-form .ant-row .ant-calendar-picker .ant-input input::placeholder {
  color: #87EFFF;
}
.count-wrap .content-wrap {
  width: 100%;
  margin: 0 20px;
  position: absolute;
  height: 88%;
  min-width: 1366px;
  min-height: 768px;
}
.count-wrap .content-wrap .name {
  position: absolute;
  text-align: center;
  color: #fff;
  font-size: 18px;
  display: inline-block;
}
.count-wrap .content-wrap .data-wrap {
  width: 24.1%;
  height: 31%;
  float: left;
  background: url(../../image/count-img/type.png) no-repeat 50%;
  background-size: 100% 100%;
  position: relative;
}
.count-wrap .content-wrap .data-wrap .name {
  position: absolute;
  top: 0.5rem;
  left: 38%;
}
.count-wrap .content-wrap .sys-wrap {
  width: 48.3%;
  height: 31%;
  float: left;
  background: url(../../image/count-img/sys.png) no-repeat 50%;
  background-size: 100% 100%;
  position: relative;
}
.count-wrap .content-wrap .sys-wrap .name {
  position: absolute;
  top: 0.5rem;
  left: 44%;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap {
  width: 100%;
  height: 100%;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record .record-title {
  margin: 50px 0 2px 13px;
  width: 100%;
  background: url(../../image/count-img/record.png) no-repeat;
  text-align: left;
  padding-left: 20px;
  font-size: 18px;
  color: #fff;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record .number .number-show {
  margin-bottom: 2%;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record .number .number-show header {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 5px;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record .number .number-show p {
  background: url(../../image/count-img/numBack.png) no-repeat;
  color: #00EFFF;
  text-align: center;
  letter-spacing: 1px;
  padding: 2px 0;
  font-family: myfont;
  font-size: 1.3rem;
}
.count-wrap .content-wrap .sys-wrap .pie-wrap .record:first-child::after {
  content: "";
  position: absolute;
  top: 49px;
  right: 0;
  width: 1px;
  bottom: 20px;
  background: #002966;
}
.count-wrap .content-wrap .inter-wrap {
  width: 24.1%;
  height: 65.4%;
  margin-left: 20px;
  position: absolute;
  right: 30px;
  background: url(../../image/count-img/top.png) no-repeat 50%;
  background-size: 100% 100%;
}
.count-wrap .content-wrap .inter-wrap .name {
  width: 101%;
  top: 7px;
  right: 1%;
}
.count-wrap .content-wrap .inter-wrap .top-ten-content {
  margin: 6% 4%;
  width: 92%;
}
.count-wrap .content-wrap .inter-wrap .top-ten-content .ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 40%;
}
.count-wrap .content-wrap .file-wrap {
  width: 24.1%;
  height: 32%;
  margin-top: 20px;
  clear: both;
  float: left;
  background: url(../../image/count-img/type.png) no-repeat 50%;
  background-size: 100% 100%;
  position: relative;
}
.count-wrap .content-wrap .file-wrap .name {
  top: 2%;
  left: 38%;
  position: absolute;
}
.count-wrap .content-wrap .record-wrap {
  width: 48.3%;
  height: 32%;
  float: left;
  margin-top: 20px;
  margin-left: 20px;
  background: url(../../image/count-img/sys.png) no-repeat 50%;
  background-size: 100% 100%;
  position: relative;
}
.count-wrap .content-wrap .record-wrap .name {
  position: absolute;
  top: 2%;
  left: 44%;
}
.count-wrap .content-wrap .fileCreate-wrap {
  width: 48.8%;
  height: 31%;
  clear: both;
  float: left;
  margin-top: 20px;
  background: url(../../image/count-img/create.png) no-repeat 50%;
  background-size: 100% 100%;
  margin-bottom: 20px;
  position: relative;
}
.count-wrap .content-wrap .fileCreate-wrap .name {
  position: absolute;
  top: 2%;
  left: 44%;
}
.count-wrap .content-wrap .dataTime-wrap {
  width: 48.8%;
  height: 31%;
  float: left;
  margin-top: 20px;
  margin-left: 20px;
  background: url(../../image/count-img/create.png) no-repeat 50%;
  background-size: 100% 100%;
  margin-bottom: 20px;
  position: relative;
}
.count-wrap .content-wrap .dataTime-wrap .name {
  position: absolute;
  top: 2%;
  left: 46%;
}
/*1366*768 -1600*/
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .count-wrap .select-wrap {
    margin-top: 8px;
    background: url(../../image/count-img/headBack.png) no-repeat 39% 2%;
  }
  .count-wrap .select-wrap .ant-form {
    top: 55px;
  }
  .count-wrap .content-wrap .data-wrap {
    width: 327px;
    position: relative;
  }
  .count-wrap .content-wrap .data-wrap .name {
    position: absolute;
    font-size: 14px;
    top: 1%;
    left: 37%;
  }
  .count-wrap .content-wrap .inter-wrap .name {
    top: 2px !important;
    font-size: 14px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show {
    height: 32px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show .progress-bar-bg {
    margin-top: 25px;
  }
  .count-wrap .content-wrap .sys-wrap {
    width: 630px;
    height: 31%;
    position: relative;
  }
  .count-wrap .content-wrap .sys-wrap .name {
    position: absolute;
    top: 1%;
    left: 43%;
    font-size: 14px;
  }
  .count-wrap .content-wrap .sys-wrap .pie-wrap .record .record-title {
    margin: 23px 0 2px 13px;
    font-size: 14px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show {
    margin-bottom: 3px !important;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show header {
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show p {
    font-size: 14px !important;
    background-size: 100% 100% !important;
  }
  .count-wrap .content-wrap .inter-wrap {
    width: 25.5%;
    height: 66%;
  }
  .count-wrap .content-wrap .file-wrap {
    margin-top: 15px;
    position: relative;
  }
  .count-wrap .content-wrap .file-wrap .name {
    position: absolute;
    top: 1%;
    font-size: 14px;
  }
  .count-wrap .content-wrap .file-wrap .import-file {
    padding-top: 10px;
  }
  .count-wrap .content-wrap .record-wrap {
    width: 630px;
    margin-top: 15px;
    margin-left: 13px;
    position: relative;
  }
  .count-wrap .content-wrap .record-wrap .name {
    position: absolute;
    top: 1%;
    left: 44%;
    font-size: 14px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    width: 660px;
    margin-top: 10px;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap .name {
    position: absolute;
    top: 1%;
    left: 43%;
    font-size: 14px;
  }
  .count-wrap .content-wrap .dataTime-wrap {
    width: 660px;
    margin-left: 15px;
    margin-top: 10px;
    height: 28%;
    position: relative;
  }
  .count-wrap .content-wrap .dataTime-wrap .name {
    position: absolute;
    top: 1%;
    font-size: 14px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    height: 28%;
    position: relative;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .count-wrap .select-wrap {
    margin-top: 8px;
    background: url(../../image/count-img/headBack.png) no-repeat 39% 2%;
  }
  .count-wrap .select-wrap .ant-form {
    top: 55px;
  }
  .count-wrap .content-wrap .data-wrap {
    width: 336px;
    position: relative;
  }
  .count-wrap .content-wrap .data-wrap .name {
    position: absolute;
    font-size: 16px;
    top: 2%;
    left: 36%;
  }
  .count-wrap .content-wrap .inter-wrap .name {
    top: 2px !important;
    font-size: 16px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show {
    height: 48px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show .progress-bar-bg {
    margin-top: 25px;
  }
  .count-wrap .content-wrap .sys-wrap {
    width: 630px;
    height: 31%;
    position: relative;
  }
  .count-wrap .content-wrap .sys-wrap .name {
    position: absolute;
    top: 2%;
    left: 42%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .sys-wrap .pie-wrap .record .record-title {
    margin: 33px 0 2px 13px;
    font-size: 15px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show {
    margin-bottom: 3px !important;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show header {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show p {
    font-size: 16px !important;
    background-size: 100% 100% !important;
  }
  .count-wrap .content-wrap .inter-wrap {
    width: 26.5%;
    height: 65%;
  }
  .count-wrap .content-wrap .file-wrap {
    margin-top: 15px;
    position: relative;
  }
  .count-wrap .content-wrap .file-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
    left: 35%;
  }
  .count-wrap .content-wrap .file-wrap .import-file {
    padding-top: 10px;
  }
  .count-wrap .content-wrap .record-wrap {
    width: 630px;
    margin-top: 15px;
    margin-left: 13px;
    position: relative;
  }
  .count-wrap .content-wrap .record-wrap .name {
    position: absolute;
    top: 2%;
    left: 42%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    width: 680px;
    margin-top: 10px;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap .name {
    position: absolute;
    top: 2%;
    left: 42%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .dataTime-wrap {
    width: 675px;
    margin-left: 15px;
    margin-top: 10px;
    height: 28%;
    position: relative;
  }
  .count-wrap .content-wrap .dataTime-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
    left: 44%;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    height: 28%;
  }
  .count-wrap .content-wrap .fileCreate-wrap canvas {
    height: 223px !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .count-wrap .select-wrap {
    margin-top: 8px;
    background: url(../../image/count-img/headBack.png) no-repeat 39% 2%;
  }
  .count-wrap .select-wrap .ant-form {
    top: 55px;
  }
  .count-wrap .content-wrap .data-wrap {
    width: 347px;
    position: relative;
  }
  .count-wrap .content-wrap .data-wrap .name {
    position: absolute;
    font-size: 16px;
    top: 2%;
    left: 35%;
  }
  .count-wrap .content-wrap .inter-wrap .name {
    top: 2px !important;
    font-size: 16px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show {
    height: 38px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show .progress-bar-bg {
    margin-top: 25px;
  }
  .count-wrap .content-wrap .sys-wrap {
    width: 670px;
    height: 31%;
    position: relative;
  }
  .count-wrap .content-wrap .sys-wrap .name {
    position: absolute;
    top: 2%;
    left: 42%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .sys-wrap .pie-wrap .record .record-title {
    margin: 30px 0 2px 13px;
    font-size: 15px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show {
    margin-bottom: 3px !important;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show header {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show p {
    font-size: 16px !important;
    background-size: 100% 100% !important;
  }
  .count-wrap .content-wrap .inter-wrap {
    width: 25.5%;
    height: 65.5%;
  }
  .count-wrap .content-wrap .file-wrap {
    margin-top: 15px;
    position: relative;
  }
  .count-wrap .content-wrap .file-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .file-wrap .import-file {
    padding-top: 10px;
  }
  .count-wrap .content-wrap .record-wrap {
    width: 670px;
    margin-top: 15px;
    margin-left: 13px;
    position: relative;
  }
  .count-wrap .content-wrap .record-wrap .name {
    position: absolute;
    top: 2%;
    left: 43%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    width: 695px;
    margin-top: 10px;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap .name {
    position: absolute;
    top: 2%;
    left: 42%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .dataTime-wrap {
    width: 700px;
    margin-left: 15px;
    margin-top: 10px;
    height: 32%;
    position: relative;
  }
  .count-wrap .content-wrap .dataTime-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    height: 32%;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap canvas {
    height: 194px !important;
  }
  .count-wrap .content-wrap .fileCreate-wrap .name {
    bottom: 30%;
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .count-wrap .select-wrap {
    margin-top: 8px;
    background: url(../../image/count-img/headBack.png) no-repeat 39% 2%;
  }
  .count-wrap .select-wrap .ant-form {
    top: 55px;
  }
  .count-wrap .content-wrap .data-wrap {
    width: 385px;
    position: relative;
  }
  .count-wrap .content-wrap .data-wrap .name {
    position: absolute;
    font-size: 16px;
    top: 2%;
    left: 38%;
  }
  .count-wrap .content-wrap .inter-wrap .name {
    top: 2px !important;
    font-size: 16px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show {
    height: 38px;
  }
  .count-wrap .content-wrap .inter-wrap .top-ten-content .progress-show .progress-bar-bg {
    margin-top: 25px;
  }
  .count-wrap .content-wrap .sys-wrap {
    width: 745px;
    height: 31%;
    position: relative;
  }
  .count-wrap .content-wrap .sys-wrap .name {
    position: absolute;
    top: 2%;
    left: 43%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .sys-wrap .pie-wrap .record .record-title {
    margin: 28px 0 2px 13px;
    font-size: 15px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show {
    margin-bottom: 3px !important;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show header {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
  }
  .count-wrap .content-wrap .sys-wrap .number .number-show p {
    font-size: 16px !important;
    background-size: 100% 100% !important;
  }
  .count-wrap .content-wrap .inter-wrap {
    width: 25.5%;
    height: 65.5%;
  }
  .count-wrap .content-wrap .file-wrap {
    margin-top: 15px;
    position: relative;
  }
  .count-wrap .content-wrap .file-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .file-wrap .import-file {
    padding-top: 10px;
  }
  .count-wrap .content-wrap .record-wrap {
    width: 745px;
    margin-top: 15px;
    margin-left: 13px;
    position: relative;
  }
  .count-wrap .content-wrap .record-wrap .name {
    position: absolute;
    top: 2%;
    left: 43%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    margin-top: 10px;
    width: 49%;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap .name {
    position: absolute;
    top: 2%;
    left: 43%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .dataTime-wrap {
    width: 48%;
    margin-left: 15px;
    margin-top: 10px;
    height: 32%;
    position: relative;
  }
  .count-wrap .content-wrap .dataTime-wrap .name {
    position: absolute;
    top: 2%;
    font-size: 16px;
  }
  .count-wrap .content-wrap .fileCreate-wrap {
    height: 32%;
    position: relative;
  }
  .count-wrap .content-wrap .fileCreate-wrap canvas {
    height: 215px !important;
  }
}
