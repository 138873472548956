.qr-code-login-container {
  position: absolute;
  display: flex;
  padding: 13px 15px;
  width: 290px;
  height: 248px;
  left: -250px;
  top: 85px;
  background: #A1D0FF;
  border: 1px solid #103e90;
  border-radius: 0 8px 8px 0;
  z-index: 9998;
}
.qr-code-login-container:hover {
  left: 0;
}
.qr-code-login-container .qr-code-login-label-wrapper {
  position: absolute;
  width: 40px;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #2262A1;
  border: 1px solid #2262A1;
  border-radius: 0 4px 4px 0;
}
.qr-code-login-container .qr-code-login-label-wrapper .qr-code-login_login-label {
  position: absolute;
  width: 20px;
  height: 104px;
  top: 65px;
  left: 8px;
  font-size: 20px;
  line-height: 26px;
  font-family: MicrosoftYaHei, serif;
  cursor: default;
  color: #FFFFFF;
}
