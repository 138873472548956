@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.box {
  background: #041D36;
  width: 100%;
}
.box .main-content {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  background: url('../image/body-bg.png') no-repeat;
  background-size: 100% 100% !important;
  height: 90%;
  border-radius: 15px;
}
.box .main-content .top-content {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: -10px;
}
.box .main-content .top-content .ant-form {
  display: inline-block;
}
.box .main-content .top-content .ant-form .ant-row {
  display: inline-block;
}
.box .main-content .top-content .ant-form .ant-row .ant-col {
  width: 390px;
  /*i.cancel{
                            position: absolute;
                            top: 1px;
                            left: 185px;
                            display: inline-block;
                            width: 19px;
                            height: 19px;
                            background: url('../image/login/cancel.png') no-repeat;
                        }*/
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-calendar-picker .ant-calendar-picker-input.ant-input {
  width: 20rem !important;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-cascader-picker {
  width: 14rem;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-cascader-picker .ant-cascader-picker-label {
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-cascader-picker .ant-cascader-input.ant-input {
  border: none;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-cascader-picker .ant-cascader-input.ant-input input::-webkit-input-placeholder {
  color: #057797;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-cascader-picker .ant-cascader-input.ant-input :-ms-input-placeholder.ant-calendar-range-picker-input {
  background: transparent ;
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .time-ranger {
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .log {
  color: #6EF9FF;
  margin-left: -2rem;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-calendar-picker .ant-calendar-picker-input.ant-input {
  width: 15rem;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-calendar-picker .ant-calendar-picker-input.ant-input input::-webkit-input-placeholder {
  color: #057797;
}
.box .main-content .top-content .ant-form .ant-row .ant-col .ant-calendar-picker .ant-calendar-picker-input.ant-input .ant-calendar-range-picker-separator {
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children .ant-select .ant-select-selection {
  display: inline-block;
  width: 250px;
  height: 32px;
  color: #6EF9FF;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children .ant-select .ant-select-selection input::-webkit-input-placeholder {
  color: #057797;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children .ant-input {
  display: inline-block;
  width: 10rem;
  height: 32px;
  position: absolute;
  margin-top: 5px;
  left: -50px;
  color: #6EF9FF;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children .ant-input input::-webkit-input-placeholder {
  color: #6EF9FF;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children span.key-words.name {
  color: #6EF9FF;
  margin-left: -53%;
}
.box .main-content .top-content .ant-form .search .ant-form-item-children span.key-words.member {
  color: #6EF9FF;
  margin-left: -32%;
}
.box .main-content .btn-group {
  display: inline-block;
  position: absolute;
  right: 1.5%;
  top: 25px;
}
.box .main-content .btn-group .ant-btn {
  background: url('../image/anniu.png') no-repeat;
  width: 110px;
  height: 32px;
  margin-left: 1px;
  background-size: 100% 100%;
  border: none;
  display: inline-block;
  color: #6EF9FF;
}
.box .main-content .btn-group .ant-btn:hover {
  background: url('../image/xuanfu.png') no-repeat;
  background-size: 100% 100%;
}
.box .main-content .btn-group .ant-btn:active {
  background: url('../image/dianji.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.box .main-content .btn-group .search-btn {
  margin-right: 20px;
}
.box .main-content .btn-group .reset-btn {
  margin-right: 20px;
}
.box .main-content .btn-group button.ant-btn.delete-btn {
  width: 110px;
  height: 32px;
  background-size: 100% 100%;
  background: url('../image/del_nor.png') no-repeat;
  color: #F24235;
}
.box .main-content .btn-group button.ant-btn.delete-btn:hover {
  background: url('../image/del_sel.png') no-repeat;
  background-size: 100% 100%;
}
.box .main-content .btn-group button.ant-btn.delete-btn:active {
  background: url('../image/del_pre.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.box .main-content .log-table {
  margin-top: -5px;
}
.box .main-content .log-table .ant-table-content {
  width: 100%;
  margin-left: 22px;
  margin-top: 20px;
}
.box .main-content .log-table .ant-table-content .ant-table-placeholder {
  background: transparent !important;
}
.box .main-content .log-table .ant-table-content .ant-table-placeholder .ant-empty-description {
  color: #6ef9ff;
}
.box .main-content .log-table .ant-table-content table {
  font-size: 16px !important;
  width: 100%;
}
.box .main-content .log-table .ant-table-content table thead th {
  background: rgba(0, 193, 222, 0.14);
  text-align: center !important;
}
.box .main-content .log-table .ant-table-content table thead th span.ant-table-header-column div span.ant-table-column-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00c1de;
}
.box .main-content .log-table .ant-table-content table thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00C4C8;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.box .main-content .log-table .ant-table-content table thead th span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .main-content .log-table .ant-table-content table .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.box .main-content .log-table .ant-table-content table tbody .ant-table-row {
  background: rgba(4, 29, 54, 0.5) !important;
}
.box .main-content .log-table .ant-table-content table tbody td {
  border: none !important;
  text-align: center !important;
  padding: 21px 0px !important;
  color: #00C1DE;
}
.box .main-content .log-table .ant-table-content table tbody td .ant-checkbox-wrapper .ant-checkbox span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .main-content .log-table .ant-table-content table tbody td .ant-checkbox-wrapper-checked .ant-checkbox-checked span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: #00C4C8;
}
.box .main-content .log-table .ant-table-content table tbody td .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #05213C;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.box .main-content .log-table .ant-table-content table tbody td a.delete {
  color: red !important;
}
.box .main-content .log-table .ant-table-content table tbody td .ant-divider,
.box .main-content .log-table .ant-table-content table tbody td .ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 2px;
  height: 20px;
  margin: 0 8px;
  vertical-align: middle;
  background: #08637B;
}
.box .main-content .log-table .ant-table-content table tbody td a.preview i {
  display: inline-block;
  width: 18px;
  height: 16px;
  background: url(../image/look.png) no-repeat !important;
}
.box .main-content .log-table .ant-table-content table tbody td a.edit {
  color: #5097FF !important;
}
.box .main-content .log-table .ant-table-content table tbody td a.edit i {
  display: inline-block;
  width: 18px;
  height: 16px;
  background: url(../image/xiazai.png) no-repeat !important;
}
.box .main-content .log-table .ant-table-content table tbody td a.delete i {
  display: inline-block;
  width: 21px;
  height: 21px;
  background: url(../image/shanchu.png) no-repeat !important;
}
.box .main-content .log-table .ant-table-content table tbody td tbody tr .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #05213C !important;
}
.box .main-content .log-table .ant-table-content table tbody td .ant-tag {
  border: none !important;
}
.box .main-content .log-table .ant-table-content table tbody tr {
  line-height: 0;
}
.box .main-content .log-table .ant-table-content tbody tr.even-bg {
  background: #05213C !important;
}
.box .main-content .log-table .ant-table-content tbody tr.odd-bg {
  background: #082B4A !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination {
  float: left !important;
  margin-left: 23px;
  margin-top: 50px !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-total-text {
  display: none !important;
  height: 25px !important;
  line-height: 22px !important;
  margin-left: 15px !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-prev {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
  border-radius: 0px !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-prev svg {
  vertical-align: 0.3em !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-options {
  display: none !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-jump-prev {
  float: left !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-jump-next {
  float: left !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #00C1DE;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-prev a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-item {
  height: 28px;
  background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(70, 255, 255, 0.5);
  border-radius: 1px;
  float: left;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-item:hover {
  border: none !important;
  background: #00C4C8 !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-item a {
  margin: 0 !important;
  color: #6EF9FF;
  line-height: 28px;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: #00C4C8 !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-next {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  border-radius: 0px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-next a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .main-content .log-table .ant-table-wrapper .ant-pagination .ant-pagination-next a svg {
  vertical-align: 0.3em !important;
}
.ant-select-dropdown.log-kinds {
  width: 250px !important;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background: #05213C;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.log-kinds ul li.ant-select-dropdown-menu-item {
  color: #00C1DE;
}
.ant-select-dropdown.log-kinds ul li.ant-select-dropdown-menu-item:hover {
  background: #043853;
}
.ant-select-dropdown.log-kinds .ant-select-dropdown-menu-item-selected {
  background: #043853;
}
.ant-cascader-menus {
  color: #6EF9FF;
  background: #0e1e2c;
}
.ant-cascader-menus li:hover {
  background: #043853;
}
.ant-cascader-menus li .ant-cascader-menu-item-expand-icon {
  color: #6EF9FF !important;
}
.ant-cascader-menus .ant-cascader-menu-item-active {
  background: #043853;
}
.ant-cascader-menus .ant-cascader-menu-item-active:hover {
  background: #043853;
}
.ant-cascader-menus .ant-cascader-menu:first-child {
  border-right: 1px solid #066f85;
}
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .box .main-content {
    height: 136%;
  }
  .box .main-content .top-content .op-number {
    width: 100px;
  }
  .box .main-content .top-content .ant-col .log {
    margin-left: -9rem !important;
  }
  .box .main-content .top-content .ant-col .name {
    margin-left: -81% !important;
  }
  .box .main-content .top-content .ant-col .member {
    margin-left: -65% !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .box .main-content {
    height: 92%;
  }
  .box .main-content .top-content .op-number {
    width: 100px;
  }
  .box .main-content .top-content .ant-col .log {
    margin-left: -9rem !important;
  }
  .box .main-content .top-content .ant-col .name {
    margin-left: -81% !important;
  }
  .box .main-content .top-content .ant-col .member {
    margin-left: -65% !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .box .main-content {
    height: 111%;
  }
  .box .main-content .top-content .op-number {
    width: 100px;
  }
  .box .main-content .top-content .ant-col .log {
    margin-left: -5rem !important;
  }
  .box .main-content .top-content .ant-col .name {
    margin-left: -67% !important;
  }
  .box .main-content .top-content .ant-col .member {
    margin-left: -49% !important;
  }
  .box .main-content .top-content .btn-group {
    right: -0.5%;
  }
  .box .main-content .top-content .btn-group .search-btn {
    margin-right: 5px;
  }
  .box .main-content .top-content .btn-group .reset-btn {
    margin-right: 5px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .box .main-content {
    height: 111%;
  }
  .box .main-content .top-content .op-number {
    width: 100px;
  }
  .box .main-content .top-content .ant-col .log {
    margin-left: -5rem !important;
  }
  .box .main-content .top-content .ant-col .name {
    margin-left: -67% !important;
  }
  .box .main-content .top-content .ant-col .member {
    margin-left: -49% !important;
  }
  .box .main-content .top-content .btn-group {
    right: 0.5%;
  }
  .box .main-content .top-content .btn-group .search-btn {
    margin-right: 5px;
  }
  .box .main-content .top-content .btn-group .reset-btn {
    margin-right: 5px;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .box .main-content {
    height: 111%;
  }
  .box .main-content .top-content .op-number {
    width: 100px;
  }
  .box .main-content .top-content .ant-col .log {
    margin-left: -4rem !important;
  }
  .box .main-content .top-content .ant-col .name {
    margin-left: -67% !important;
  }
  .box .main-content .top-content .ant-col .member {
    margin-left: -42% !important;
  }
  .box .main-content .top-content .btn-group {
    right: 0.5%;
  }
  .box .main-content .top-content .btn-group .search-btn {
    margin-right: 5px;
  }
  .box .main-content .top-content .btn-group .reset-btn {
    margin-right: 5px;
  }
}
