.selectModal .ant-modal-footer {
  border: 0;
}
.selectBox {
  height: 33rem;
}
.ant-btn-circle {
  width: 45px !important;
}
.ant-btn-circle i {
  color: white;
}
.selectLeft {
  width: 62%;
  height: 100%;
  float: left;
  background: #fff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  position: relative;
}
.selectLeftSingle {
  width: 100%;
  height: 100%;
  float: left;
  background: #fff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  position: relative;
}
.deptContent {
  margin: 0;
}
.deptContent > div:first-child {
  margin: 0;
}
.deptContent > div:last-child {
  width: 53% !important;
  display: inline-block;
  height: 30.4rem;
  overflow: auto;
  border-right: 1px solid #d8d8d8;
}
.memBox {
  position: absolute;
  top: 40px;
  right: 0;
  width: 47%;
  padding: 0;
  margin: 0;
  height: 29.4rem;
  overflow: auto;
}
.memBox li {
  line-height: 30px;
  padding-left: 15px;
  cursor: pointer;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.memBox li i {
  margin-right: 5px;
}
.deptName {
  float: right;
  font-size: 12px;
  color: #999;
}
.selectBtn {
  float: left;
  width: 6%;
  padding-left: 1.4%;
  position: relative;
  top: 45%;
}
.selectRight {
  width: 30%;
  height: 100%;
  float: right;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  box-sizing: border-box;
  overflow: auto;
}
.selectRight li {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
