.portal-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(122, 120, 100, 0.5);
  z-index: 9999;
}
.portal-center {
  position: absolute;
  margin: auto;
  width: 600px;
  height: 400px;
  left: 50%;
  top: 50%;
  /*border: 5px solid white;*/
  border-radius: 15px;
  background-color: #1b435d;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.portal-center_wrapper {
  position: absolute;
  left: 50%;
  top: 40%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.portal-center_lock-tab {
  color: white;
  font-size: 36px;
}
.portal-center_lock-tip {
  color: white;
  font-size: 15px;
}
.portal-center-input {
  margin-top: 20px;
  width: 100%;
}
.portal-center_confirm {
  position: absolute;
  left: 50%;
  top: 65%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
