@charset 'utf-8';
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.pitViewList {
  margin-left: 23px;
  margin-top: 20px;
}
.pitViewList .pit-list {
  display: inline-block;
  position: relative;
  width: 18%;
  height: 195px;
  border: 1px solid #00c1de;
  margin-right: 2%;
  margin-bottom: 8px;
  color: #fff;
  text-align: center;
  line-height: 210px;
}
.pitViewList .pit-list img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.pitViewList .pit-list .musicImg {
  width: 80px;
  height: 80px;
  top: 30%;
  left: 38%;
}
.pitViewList .pit-list .videoImg {
  width: 80px;
  height: 80px;
  top: 30%;
  left: 38%;
}
.pitViewList .pit-list .doc-detail {
  position: absolute;
  height: 60px;
  background: rgba(5, 16, 26, 0.7);
  width: 100%;
  bottom: 0;
}
.pitViewList .pit-list .operate {
  position: absolute;
  right: 0;
  width: 120px;
  height: 40px;
  background: rgba(5, 16, 26, 0.7);
}
.pitViewList .pit-list .operate i.download {
  display: inline-block;
  background: url('../image/xiazai.png') no-repeat center;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 9px;
  right: 60px;
  border-right: 1px solid #074962;
  padding-right: 56px;
}
.pitViewList .pit-list .operate i.delete {
  display: inline-block;
  background: url('../image/shanchu.png') no-repeat center;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 9px;
  right: 20px;
}
.pitViewList .pit-list .ant-checkbox-group-custom {
  position: absolute;
  right: 9px;
  bottom: 0;
  width: 15px;
  height: 15px;
}
.pitViewList .pit-list .ant-checkbox-group-custom .ant-checkbox-inner {
  background: #1B2F54;
  border: 1px solid #267b8d;
}
.pitViewList .pit-list .ant-checkbox-group {
  position: absolute;
  right: 16px;
  bottom: -6px;
}
.pitViewList .pit-list .ant-checkbox-group .ant-checkbox-inner {
  background: #1B2F54;
  border: 1px solid #267b8d;
}
.pitViewList .pit-list span {
  position: absolute;
  font-size: 12px;
  line-height: 15px;
}
.pitViewList .pit-list span.dev-nem {
  left: 5%;
  bottom: 18%;
}
.pitViewList .pit-list span.username {
  left: 5%;
  bottom: 10%;
}
.pitViewList .pit-list span.pitTime {
  left: 5%;
  bottom: 2%;
}
ul.ant-pagination {
  margin-left: 23px;
  margin-top: 0%;
  padding-bottom: 20px;
}
ul.ant-pagination .ant-pagination-total-text {
  display: inline-block !important;
  color: #00C1DE;
  font-size: 16px;
  height: 25px !important;
  line-height: 22px !important;
  margin-left: 15px !important;
  margin-top: 3px !important;
}
ul.ant-pagination .ant-pagination-prev {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
  border-radius: 0px !important;
}
ul.ant-pagination .ant-pagination-prev svg {
  vertical-align: 0.3em !important;
}
ul.ant-pagination .ant-pagination-options {
  display: none !important;
}
ul.ant-pagination .ant-pagination-jump-prev {
  float: left !important;
}
ul.ant-pagination .ant-pagination-jump-next {
  float: left !important;
}
ul.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
ul.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #6EF9FF !important;
}
ul.ant-pagination .ant-pagination-prev a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
ul.ant-pagination .ant-pagination-item {
  height: 28px;
  background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(70, 255, 255, 0.5);
  border-radius: 1px;
  float: left;
}
ul.ant-pagination .ant-pagination-item:hover {
  border: none !important;
  background: #00C4C8 !important;
}
ul.ant-pagination .ant-pagination-item a {
  margin: 0 !important;
  color: #6EF9FF;
  line-height: 28px;
}
ul.ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: #00C4C8 !important;
}
ul.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
ul.ant-pagination .ant-pagination-next {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  border-radius: 0px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
}
ul.ant-pagination .ant-pagination-next a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
ul.ant-pagination .ant-pagination-next a svg {
  vertical-align: 0.3em !important;
}
.prev-modal2 {
  top: 20%;
  z-index: 99999999999 !important;
}
.prev-modal2 .ant-modal-content .ant-modal-close-x {
  line-height: 38px;
  color: #fff;
}
.prev-modal2 .ant-modal-content .ant-modal-header {
  padding: 10px 24px;
  background: #013765;
  border-bottom: none;
}
.prev-modal2 .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
}
.prev-modal2 .ant-modal-content .ant-modal-body {
  background: #05213C;
  position: relative;
  padding: 0px;
}
.prev-modal2 .ant-modal-content .ant-modal-body .prev-img {
  width: 100%;
}
.prev-modal2 .ant-modal-content .ant-modal-body .pit-music {
  width: 100%;
  height: 200px;
}
.prev-modal2 .ant-modal-content .ant-modal-body .pit-music .prev-audio {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0;
}
.prev-modal2 .ant-modal-content .ant-modal-body .pit-music .prev-audio2 {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
}
.prev-modal2 .ant-modal-content .ant-modal-body .pit-music img {
  position: absolute;
  left: 220px;
  top: 45px;
}
.prev-modal2 .ant-modal-content .ant-modal-body .prev-video {
  width: 100%;
}
.prev-modal2 .ant-modal-content .ant-modal-body .prev-video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.prev-modal2 .ant-modal-content .ant-modal-footer {
  padding: 20px 16px;
  background: #06315C;
  border-top: none;
  text-align: center;
}
.prev-modal2 .ant-modal-content .ant-modal-footer .ant-btn {
  display: none;
}
.prev-modal2 .ant-modal-content .ant-modal-footer .msg-size {
  text-align: left;
  font-size: 16px;
  color: #fff;
}
.prev-modal2 .ant-modal-content .ant-modal-footer .msg-mem {
  color: #fff;
  text-align: right;
  font-size: 16px;
}
@media screen and (max-width: 1360px) {
  .pitViewList {
    margin-left: 23px;
    margin-top: 20px;
  }
  .pitViewList .pit-list {
    display: inline-block;
    position: relative;
    width: 18%;
    height: 8.5rem;
    border: 1px solid #00c1de;
    margin-right: 2%;
    margin-bottom: 8px;
    color: #fff;
    text-align: center;
    line-height: 210px;
  }
  .pitViewList .pit-list img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .pitViewList .pit-list .musicImg {
    width: 3.5rem;
    height: 3.5rem;
    top: 30%;
    left: 38%;
  }
  .pitViewList .pit-list .videoImg {
    width: 3.5rem;
    height: 3.5rem;
    top: 30%;
    left: 38%;
  }
  .pitViewList .pit-list .doc-detail {
    position: absolute;
    height: 3rem;
    background: rgba(5, 16, 26, 0.7);
    width: 100%;
    bottom: 0;
  }
  .pitViewList .pit-list .operate {
    position: absolute;
    right: 0;
    width: 120px;
    height: 40px;
    background: rgba(5, 16, 26, 0.7);
  }
  .pitViewList .pit-list .operate i.download {
    display: inline-block;
    background: url('../image/xiazai.png') no-repeat center;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 9px;
    right: 60px;
    border-right: 1px solid #074962;
    padding-right: 56px;
  }
  .pitViewList .pit-list .operate i.delete {
    display: inline-block;
    background: url('../image/shanchu.png') no-repeat center;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 9px;
    right: 20px;
  }
  .pitViewList .pit-list .ant-checkbox {
    position: absolute;
    right: 16px;
    bottom: -6px;
  }
  .pitViewList .pit-list .ant-checkbox .ant-checkbox-inner {
    background: #1B2F54;
    border: 1px solid #267b8d;
  }
  .pitViewList .pit-list .ant-checkbox-group {
    position: absolute;
    right: 16px;
    bottom: -6px;
  }
  .pitViewList .pit-list .ant-checkbox-group .ant-checkbox-inner {
    background: #1B2F54;
    border: 1px solid #267b8d;
  }
  .pitViewList .pit-list span {
    position: absolute;
    font-size: 12px;
    line-height: 15px;
  }
  .pitViewList .pit-list span.dev-nem {
    left: 5%;
    bottom: 18%;
    height: 20px;
  }
  .pitViewList .pit-list span.username {
    left: 5%;
    bottom: 10%;
    height: 18px;
  }
  .pitViewList .pit-list span.pitTime {
    left: 5%;
    bottom: 2%;
  }
  ul.ant-pagination {
    margin-left: 23px;
    margin-top: 3%;
    padding-bottom: 20px;
  }
  ul.ant-pagination .ant-pagination-total-text {
    display: none !important;
    height: 25px !important;
    line-height: 22px !important;
    margin-left: 15px !important;
  }
  ul.ant-pagination .ant-pagination-prev {
    height: 28px !important;
    line-height: 28px !important;
    float: left !important;
    width: 58px !important;
    background: #082A4A !important;
    border: 1px solid #00c1de !important;
    border-radius: 0px !important;
  }
  ul.ant-pagination .ant-pagination-prev svg {
    vertical-align: 0.3em !important;
  }
  ul.ant-pagination .ant-pagination-options {
    display: none !important;
  }
  ul.ant-pagination .ant-pagination-jump-prev {
    float: left !important;
  }
  ul.ant-pagination .ant-pagination-jump-next {
    float: left !important;
  }
  ul.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  ul.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #6EF9FF !important;
  }
  ul.ant-pagination .ant-pagination-prev a {
    width: 25px !important;
    height: 25px !important;
    border-radius: 0px !important;
    padding: 3px 5px !important;
    color: #00C1DE !important;
  }
  ul.ant-pagination .ant-pagination-item {
    height: 28px;
    background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
    border: 1px solid rgba(70, 255, 255, 0.5);
    border-radius: 1px;
    float: left;
  }
  ul.ant-pagination .ant-pagination-item:hover {
    border: none !important;
    background: #00C4C8 !important;
  }
  ul.ant-pagination .ant-pagination-item a {
    margin: 0 !important;
    color: #6EF9FF;
    line-height: 28px;
  }
  ul.ant-pagination .ant-pagination-item-active {
    border: none !important;
    background: #00C4C8 !important;
  }
  ul.ant-pagination .ant-pagination-item-active a {
    color: #fff !important;
  }
  ul.ant-pagination .ant-pagination-next {
    height: 28px !important;
    line-height: 28px !important;
    float: left !important;
    width: 58px !important;
    border-radius: 0px !important;
    background: #082A4A !important;
    border: 1px solid #00c1de !important;
  }
  ul.ant-pagination .ant-pagination-next a {
    width: 25px !important;
    height: 25px !important;
    border-radius: 0px !important;
    padding: 3px 5px !important;
    color: #00C1DE !important;
  }
  ul.ant-pagination .ant-pagination-next a svg {
    vertical-align: 0.3em !important;
  }
  .prev-modal2 {
    top: 20%;
    z-index: 99999999999 !important;
  }
  .prev-modal2 .ant-modal-content .ant-modal-close-x {
    line-height: 38px;
    color: #fff;
  }
  .prev-modal2 .ant-modal-content .ant-modal-header {
    padding: 10px 24px;
    background: #013765;
    border-bottom: none;
  }
  .prev-modal2 .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #fff;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body {
    background: #05213C;
    position: relative;
    padding: 0px;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .prev-img {
    width: 100%;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .pit-music {
    width: 100%;
    height: 200px;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .pit-music .prev-audio {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .pit-music .prev-audio2 {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .pit-music img {
    position: absolute;
    left: 220px;
    top: 45px;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .prev-video {
    width: 100%;
  }
  .prev-modal2 .ant-modal-content .ant-modal-body .prev-video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  .prev-modal2 .ant-modal-content .ant-modal-footer {
    padding: 20px 16px;
    background: #06315C;
    border-top: none;
    text-align: center;
  }
  .prev-modal2 .ant-modal-content .ant-modal-footer .ant-btn {
    display: none;
  }
  .prev-modal2 .ant-modal-content .ant-modal-footer .msg-size {
    text-align: left;
    font-size: 16px;
    color: #fff;
  }
  .prev-modal2 .ant-modal-content .ant-modal-footer .msg-mem {
    color: #fff;
    text-align: right;
    font-size: 16px;
  }
}
/*
@media screen and (max-width: 1366px)
{
    .pitViewList{
        margin-left: 23px;
        margin-top: 20px;
        .pit-list{
            display: inline-block;
            position: relative;
            width: 18%;
            height: 8.5rem;
            border: 1px solid rgba(0,193,222,1);
            margin-right: 2%;
            margin-bottom: 8px;
            color: #fff;
            text-align: center;
            line-height: 210px;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            .musicImg{
                width: 3.5rem;
                height: 3.5rem;
                top: 30%;
                left: 38%;
            }
            .videoImg{
                width: 3.5rem;
                height: 3.5rem;
                top: 30%;
                left: 38%;
            }
            .doc-detail{
                position: absolute;
                height: 3rem;
                background:rgba(5,16,26,0.7);
                width: 100%;
                bottom: 0;
            }
            .operate{
                position: absolute;
                right: 0;
                width: 120px;
                height: 40px;
                background: rgba(5,16,26,0.7);
                i.download{
                    display: inline-block;
                    background: url('../image/xiazai.png') no-repeat center;
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    top: 9px;
                    right: 60px;
                    border-right: 1px solid #074962;
                    padding-right: 56px;
                }
                i.delete{
                    display: inline-block;
                    background: url('../image/shanchu.png') no-repeat center;
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    top: 9px;
                    right: 20px;
                }
            }
            .ant-checkbox-group{
                position: absolute;
                right: 16px;
                bottom: -6px;
                z-index: 9;
                .ant-checkbox-inner{
                    background: #1B2F54;
                    border: 1px solid #267b8d;
                }
            }
            span{
                position: absolute;
                font-size: 12px;
                line-height: 15px;
            }
            span.dev-nem {
                left: 5%;
                bottom: 18%;
                
            }
            span.username {
                left: 5%;
                bottom: 10%;
            }
            span.pitTime{
               left: 5%;
               bottom: 2%;
            }
        }
    }
    ul.ant-pagination{    
        float:  left !important;
        margin-left: 23px;
        margin-top: 6.3%;
        .ant-pagination-total-text{
            display: none !important;
            height: 25px !important;
            line-height: 22px !important;
            margin-left: 15px !important;
        }
        .ant-pagination-prev{
            height: 28px !important;
            line-height: 28px !important;       
            float:  left !important;
            width: 58px !important;
            background: #082A4A !important;
            border: 1px solid rgba(0,193,222,1) !important; 
            border-radius: 0px !important;
            svg{
                vertical-align: 0.3em !important;
            }
        }
        .ant-pagination-options{
            display: none !important;
        }
        .ant-pagination-jump-prev{
            float: left !important;
        }
    
        .ant-pagination-jump-next{
            float: left !important;
        }
        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis{
            color: #6EF9FF !important;  
        }
        .ant-pagination-prev a{
            width: 25px !important;
            height: 25px !important;
            border-radius: 0px !important;
            padding: 3px 5px !important;
            color: #00C1DE !important;
        }
        .ant-pagination-item{
            width:30px;
            height:28px;
            background:linear-gradient(0deg,rgba(250,252,255,0.05),rgba(0,0,0,0.05));
            border:1px solid rgba(70, 255, 255, 0.5);
            border-radius:1px;
            float: left;
            
            &:hover{
                border: none !important;
                background: #00C4C8 !important;
            }
            a{
                margin: 0 !important;
                color: #6EF9FF;
                line-height: 28px;
            }
        }
        .ant-pagination-item-active{
            border: none !important;
            background: #00C4C8 !important;
            a{
                color: #fff !important;
            }
        }
        .ant-pagination-next{
            height: 28px !important;
            line-height: 28px !important;
            float: left !important;
            width: 58px !important;
            border-radius: 0px !important;
            background: #082A4A !important;
            border: 1px solid rgba(0,193,222,1) !important; 
            a{
                width: 25px !important;
                height: 25px !important;
                border-radius: 0px !important;
                padding: 3px 5px !important;
                color: #00C1DE !important;
                svg{
                    vertical-align: 0.3em !important;
                }
            }
        }
       
       
    }  
    .prev-modal2{
        top: 20%;
        z-index: 99999999999 !important;
        .ant-modal-content{
            .ant-modal-close-x{
                line-height: 38px;
                color: #fff;
            }
            .ant-modal-header{
                padding: 10px 24px; 
                background: #013765;
                border-bottom: none;
                .ant-modal-title{
                    color: #fff;
                }
            }
            .ant-modal-body{
                background: #05213C;
                position: relative;
                padding: 0px;
                .prev-img{
                    width: 100%;
                }
                .pit-music{
                    width: 100%;
                    height: 200px;
                    .prev-audio{
                        width: 100%;
                        height: 20px;
                        position: absolute;
                        bottom: 0;
                    }
                    .prev-audio2{
                        width: 100%;
                        height: 40px;
                        position: absolute;
                        bottom: 0;
                    }
                    img{
                        position: absolute;
                        left: 220px;
                        top: 45px
                    }
                }
                .prev-video{
                    width: 100%;
                }
                .prev-video::-webkit-media-controls-fullscreen-button {
                    display: none;
                }
            }
            .ant-modal-footer{
                padding: 20px 16px;
                background: #06315C;
                border-top: none;
                text-align: center;
                .ant-btn{
                    display: none;
                }
                .msg-size{
                    text-align: left;
                    font-size: 16px;
                    color: #fff;
                }
                .msg-mem{
                    color: #fff;
                    text-align: right;
                    margin-left: 35%;
                    font-size: 16px;
                }
            }
        }
    }
} */
