@charset 'utf-8';
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  box-sizing: border-box;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.home-page {
  height: 100%;
  overflow-y: hidden;
}
.home-page .login-box {
  background: url('../../image/login/bg.png') no-repeat;
  height: 100%;
  background-size: 100% 100%;
}
.home-page .login-box .top-header {
  width: 100%;
  height: 60px;
  background: rgba(13, 208, 252, 0.1);
}
.home-page .login-box .top-header .sosMusic {
  display: none;
}
.home-page .login-box .top-header .top-left {
  display: inline-block;
  line-height: 60px;
  margin-left: 25px;
}
.home-page .login-box .top-header .top-left .top-title {
  font-size: 24px;
  color: #fff;
  font-family: Microsoft YaHei;
}
.home-page .login-box .top-header .top-right {
  margin-right: 30px;
  display: inline-block;
  color: #fff;
  float: right;
  line-height: 60px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  position: relative;
}
.home-page .login-box .top-header .top-right .help {
  display: inline-block;
  margin-right: 30px;
  visibility: hidden;
}
.home-page .login-box .top-header .top-right .help .help-icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  background: url(../../image/help/help.png) no-repeat;
  vertical-align: middle;
  margin-right: 5px;
}
.home-page .login-box .top-header .top-right .help .doc-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('../../image/help/word.png') no-repeat;
  vertical-align: middle;
  margin-right: 3px;
  margin-left: 5px;
}
.home-page .login-box .top-header .top-right .help .video-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('../../image/help/video.png') no-repeat;
  vertical-align: middle;
  margin-right: 5px;
}
.home-page .login-box .top-header .top-right .sos-box {
  display: inline-block;
}
.home-page .login-box .top-header .top-right .sos-box .police-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../../image/police/police.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -16%;
  top: 36%;
}
.home-page .login-box .top-header .top-right .sos-box .police-count {
  width: 15px;
  height: 15px;
  background: #FD3D33;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  padding: 0 4px;
  line-height: 15px;
  position: absolute;
  left: -10%;
  top: 32%;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap {
  display: none;
  width: 190%;
  background: #013F63;
  position: absolute;
  right: 0;
  margin-top: 8%;
  z-index: 99;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap::before {
  content: '';
  width: 0;
  height: 0;
  border: 10px solid;
  position: absolute;
  top: -20px;
  right: 55%;
  border-color: transparent transparent #013F63;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list {
  height: 35px;
  line-height: 35px;
  color: #A9F8FF;
  border-bottom: 1px solid #003556;
  font-size: 14px;
  cursor: pointer;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list i {
  margin-left: 4%;
  margin-right: 3%;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #EC3839;
  border-radius: 100%;
  margin-bottom: 4%;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list .sos-content {
  display: inline-block;
  width: 72%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list:hover {
  background: #005A79;
  color: #fff;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list .ignore-btn {
  display: none;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap .sos-ul .sos-list .ignore-btn.isShow {
  display: inline-block;
  color: #00FFFF;
  padding-left: 15px;
  vertical-align: top;
}
.home-page .login-box .top-header .top-right .sos-box .sos-wrap.isShow {
  display: block;
}
.home-page .login-box .top-header .top-right .date-time {
  display: inline-block;
}
.home-page .login-box .login-content {
  width: 100%;
  height: calc(100% - 60px);
  min-width: 1366px;
}
.home-page .login-box .login-content .login-form {
  margin-top: 25px;
  margin-left: 25px;
  width: 300px;
  height: 92%;
  background: rgba(0, 64, 125, 0.5);
  float: left;
}
.home-page .login-box .login-content .login-form .user-message {
  margin-top: 10% !important;
  margin-left: 0px !important;
}
.home-page .login-box .login-content .login-form .user-message .user-avatar img {
  display: block;
  width: 100px;
  height: 100px;
  background: url('../../image/login/noLogin.png') no-repeat;
  margin: 0 auto;
  border-radius: 100%;
}
.home-page .login-box .login-content .login-form .user-message .login-title {
  width: 100%;
  height: 45px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  margin-top: 20px;
}
.home-page .login-box .login-content .login-form .longtime {
  margin-top: 20px;
  text-align: center;
  color: #ACB8C4;
}
.home-page .login-box .login-content .login-form .longtime .changeable {
  color: #00FCFF;
}
.home-page .login-box .login-content .login-form .login-time {
  margin-top: 10px;
  text-align: center;
  color: #ACB8C4;
}
.home-page .login-box .login-content .login-form .device-data {
  position: relative;
  background: rgba(12, 14, 26, 0.2);
  height: 82%;
  color: #ACB8C4;
  margin-top: 7%;
  padding-top: 10%;
}
.home-page .login-box .login-content .login-form .device-data .device-title {
  padding-left: 10%;
}
.home-page .login-box .login-content .login-form .device-data .device-pit {
  width: 150px;
  height: 150px;
}
.home-page .login-box .login-content .login-form .device-data .device-pit #pitPie1 {
  margin-left: 35%;
  padding-top: 10%;
}
.home-page .login-box .login-content .login-form .device-data .device-pit #pitPie2 {
  margin-left: 35%;
}
.home-page .login-box .login-content .login-form .device-data .device-pit .divider {
  width: 15rem;
  height: 1px;
  background: #003273;
  margin-left: 20%;
  margin: 10px 20%;
}
.home-page .login-box .login-content .login-form .device-data .device-pit1 {
  width: 150px;
  height: 150px;
}
.home-page .login-box .login-content .login-form .device-data .device-pit1 #pitPie2 {
  margin-left: 35%;
  margin-top: 55%;
}
.home-page .login-box .login-content .login-form .device-data .device-pit1 .divider {
  width: 15rem;
  height: 1px;
  background: #003273;
  margin-left: 20%;
  margin: 10px 20%;
}
.home-page .login-box .login-content .login-form .device-data .logout-btn {
  position: absolute;
  width: 100%;
  bottom: 25%;
  left: 20%;
}
.home-page .login-box .login-content .login-form .device-data .logout-btn .amend-btn,
.home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
  background: transparent;
  outline: none;
  border: none;
  color: #00f2f6;
  font-size: 18px;
  font-family: Microsoft YaHei;
  cursor: pointer;
}
.home-page .login-box .login-content .login-form .device-data .logout-btn .amend-btn {
  margin-left: -7%;
}
.home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
  margin-left: 20%;
}
.home-page .login-box .nav-list {
  float: left;
  position: relative;
  margin-left: 18%;
  margin-top: -43%;
}
.home-page .login-box .nav-list .list1-title {
  color: #fff;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-left: 4px solid #00e8fb;
  margin-left: 50px;
  margin-top: 100px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.home-page .login-box .nav-list .list1 {
  width: 56%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 50%;
  margin-left: 50px;
}
.home-page .login-box .nav-list .list1 .item1 {
  cursor: pointer;
  position: relative;
  width: 480px;
  height: 350px;
  background: url('../../image/login/gis.png') no-repeat;
  color: #fff;
}
.home-page .login-box .nav-list .list1 .item1 .title {
  height: 40px;
  background: rgba(4, 24, 53, 0.8);
  line-height: 40px;
  padding-left: 20px;
  margin-top: 310px;
}
.home-page .login-box .nav-list .list1 .item1:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list1 .item2 {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  width: 280px;
  background: url('../../image/login/1.png') no-repeat;
  color: #fff;
}
.home-page .login-box .nav-list .list1 .item2 i {
  display: block;
  width: 64px;
  height: 52px;
  background: url('../../image/login/jkgl\ .png') no-repeat center;
  margin-left: 108px;
  margin-top: 130px;
}
.home-page .login-box .nav-list .list1 .item2 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list1 .item2:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list1 .item3 {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  width: 235px;
  height: 250px;
  flex-wrap: wrap;
  color: #fff;
  background: url('../../image/login/1.png') no-repeat;
}
.home-page .login-box .nav-list .list1 .item3 i {
  width: 50px;
  height: 62px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 80px;
  margin-left: 90px;
}
.home-page .login-box .nav-list .list1 .item3 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list1 .item3:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list1 .item4 {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  width: 235px;
  height: 250px;
  background: url('../../image/login/3.png') no-repeat;
  color: #fff;
}
.home-page .login-box .nav-list .list1 .item4 i {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/gjjl.png') no-repeat center;
  margin-top: 80px;
  margin-left: 90px;
}
.home-page .login-box .nav-list .list1 .item4 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list1 .item4:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list1 .item5 {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  width: 280px;
  height: 250px;
  background: url('../../image/login/tongji.png') no-repeat;
  color: #fff;
}
.home-page .login-box .nav-list .list1 .item5 .title {
  height: 40px;
  background: rgba(4, 24, 53, 0.8);
  line-height: 40px;
  padding-left: 20px;
  margin-top: 210px;
}
.home-page .login-box .nav-list .list1 .item5:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .item11 {
  position: absolute;
  margin-top: 440px;
  width: 310px;
  height: 170px;
  background: url('../../image/login/7.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-poc-analysis {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item11 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .item11 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item11:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2-title {
  color: #fff;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-left: 4px solid #00e8fb;
  margin-top: -670px;
  margin-left: 920px;
  padding-left: 20px;
}
.home-page .login-box .nav-list .module-manager {
  display: inline-block;
}
.home-page .login-box .nav-list .list2 {
  width: 630px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 920px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .part1 {
  width: 310px;
  height: 350px;
  position: absolute;
  background: url('../../image/login/4.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .part1 i {
  display: block;
  width: 80px;
  height: 80px;
  background: url('../../image/login/tianjia.png') no-repeat;
  margin-top: 40%;
  margin-left: 36%;
}
.home-page .login-box .nav-list .list2 .part2 {
  width: 310px;
  height: 170px;
  margin-left: 320px;
  position: absolute;
  background: url('../../image/login/5.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .part2 i {
  display: block;
  width: 80px;
  height: 80px;
  background: url('../../image/login/tianjia.png') no-repeat;
  margin-top: 18%;
  margin-left: 36%;
}
.home-page .login-box .nav-list .list2 .part3 {
  width: 310px;
  height: 170px;
  position: absolute;
  background: url('../../image/login/6.png') no-repeat center;
  margin-left: 320px;
  margin-top: 180px;
}
.home-page .login-box .nav-list .list2 .part3 i {
  display: block;
  width: 80px;
  height: 80px;
  background: url('../../image/login/tianjia.png') no-repeat;
  margin-top: 18%;
  margin-left: 36%;
}
.home-page .login-box .nav-list .list2 .part4 {
  position: absolute;
  width: 310px;
  height: 250px;
  background: url('../../image/login/7.png') no-repeat center;
  margin-top: 360px;
}
.home-page .login-box .nav-list .list2 .part4 i {
  display: block;
  width: 80px;
  height: 80px;
  background: url('../../image/login/tianjia.png') no-repeat;
  margin-top: 30%;
  margin-left: 36%;
}
.home-page .login-box .nav-list .list2 .part5 {
  position: absolute;
  width: 310px;
  height: 250px;
  background: url('../../image/login/8.png') no-repeat center;
  margin-left: 320px;
  margin-top: 360px;
}
.home-page .login-box .nav-list .list2 .part5 i {
  display: block;
  width: 80px;
  height: 80px;
  background: url('../../image/login/tianjia.png') no-repeat;
  margin-top: 30%;
  margin-left: 36%;
}
.home-page .login-box .nav-list .list2 .title {
  color: #fff;
}
.home-page .login-box .nav-list .list2 .item1 {
  position: absolute;
  width: 310px;
  height: 350px;
  background: url('../../image/login/4.png') no-repeat center;
  float: left;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 40%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 40%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 40%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-dev {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 40%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item1 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 40%;
  margin-left: 38%;
}
.home-page .login-box .nav-list .list2 .item1 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .item1 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item1:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .item2 {
  position: absolute;
  margin-left: 320px;
  width: 310px;
  height: 170px;
  background: url('../../image/login/5.png') no-repeat center;
  float: left;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-dev {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item2 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .item2 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item2:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .item3 {
  position: absolute;
  margin-left: 320px;
  margin-top: 180px;
  width: 310px;
  height: 170px;
  background: url('../../image/login/6.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-dev {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item3 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 12%;
  margin-left: 37%;
}
.home-page .login-box .nav-list .list2 .item3 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}
.home-page .login-box .nav-list .list2 .item3 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item3:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .item4 {
  position: absolute;
  margin-top: 360px;
  width: 310px;
  height: 250px;
  background: url('../../image/login/7.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-dev {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item4 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .item4 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item4:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .item5 {
  position: absolute;
  margin-top: 360px;
  margin-left: 320px;
  width: 310px;
  height: 250px;
  background: url('../../image/login/8.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-data-statistics {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/datastatistics.png') no-repeat center;
  margin-top: 20%;
  margin-left: 40%;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-dept {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/bmgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 38%;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-das {
  width: 63px;
  height: 61px;
  display: block;
  background: url('../../image/login/caijizhan.png') no-repeat center;
  margin-top: 20%;
  margin-left: 38%;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-group {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/zdgl.png') no-repeat center;*/
  background: url('../../image/login/thzgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 38%;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-dev {
  width: 63px;
  height: 61px;
  display: block;
  /*background: url('../../image/login/thzgl.png') no-repeat center;*/
  background: url('../../image/login/zdgl.png') no-repeat center;
  margin-top: 20%;
  margin-left: 38%;
}
.home-page .login-box .nav-list .list2 .item5 i.menu-oplog {
  width: 84px;
  height: 80px;
  display: block;
  background: url('../../image/login/wjgl.png') no-repeat center;
  margin-top: 16%;
  margin-left: 35%;
}
.home-page .login-box .nav-list .list2 .item5 .title {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.home-page .login-box .nav-list .list2 .item5 .remove-icon {
  z-index: 9999;
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -2%;
  right: -2%;
  background: url('../../image/login/shanchu.png') no-repeat center;
}
.home-page .login-box .nav-list .list2 .item5:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-color: #000;
  opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-page .login-box .nav-list .list2 .add-1 {
  position: relative;
  display: block;
  width: 310px;
  height: 350px;
  background: url('../../image/login/4.png') no-repeat center;
}
.home-page .login-box .nav-list .module-manager {
  cursor: pointer;
  position: absolute;
  margin-left: 1450px;
  margin-top: -30px;
}
.home-page .login-box .nav-list .module-manager i {
  display: inline-block;
  background: url('../../image/login/gl.png') no-repeat center;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.home-page .login-box .nav-list .module-manager .module-text {
  color: #00e8fb;
  font-size: 16px;
  margin-left: 10px;
}
.add-config .ant-modal-content {
  width: 360px;
  height: 380px;
  margin-left: 40%;
  margin-top: 30%;
  background: url('../../image/login/kuang.png') no-repeat;
  background-size: 100% 100%;
}
.add-config .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.add-config .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 18px;
}
.add-config .ant-modal-content .ant-modal-body {
  background: rgba(2, 36, 70, 0.7) !important;
  margin: 0 22px 10px 22px;
}
.add-config .ant-modal-content .ant-modal-body .ant-form .ant-form-item label {
  color: #00C1DE;
  font-size: 16px;
}
.add-config .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.add-config .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.add-config .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  padding: 0 !important;
}
.add-config .ant-modal-content .ant-modal-footer .ant-btn {
  background: url('../../image/login/anniu.png') no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 45px;
}
.add-config .ant-modal-content .ant-modal-footer .ant-btn span {
  color: #7DFAFF;
}
.add-config .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../../image/guanbi.png') no-repeat;
  margin-top: -5px;
  margin-right: -5px;
}
.add-config .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: block;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff !important;
}
.reset-pass .ant-modal-content {
  width: 400px;
  height: 480px;
  margin-left: 40%;
  margin-top: 30%;
  background: url('../../image/login/kuang.png') no-repeat;
  background-size: 100% 100%;
}
.reset-pass .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.reset-pass .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 18px;
}
.reset-pass .ant-modal-content .ant-modal-body {
  background: rgba(2, 36, 70, 0.7) !important;
  margin: 0 22px 10px 22px;
}
.reset-pass .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-input {
  width: 310px !important;
  height: 40px;
  background: rgba(2, 36, 70, 0.8);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #7DFAFF;
}
.reset-pass .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-form-item-label > label {
  color: #7DFAFF;
}
.reset-pass .ant-modal-content .ant-modal-body .ant-form .ant-form-item .has-error .ant-form-explain {
  position: absolute;
}
.reset-pass .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  padding: 0 !important;
}
.reset-pass .ant-modal-content .ant-modal-footer .ant-btn {
  background: url('../../image/login/anniu.png') no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 58px;
}
.reset-pass .ant-modal-content .ant-modal-footer .ant-btn span {
  color: #7DFAFF;
}
.reset-pass .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: #002446;
  border: 1px solid #45fcfc;
  border-radius: 50%;
  margin-top: -5px;
  margin-right: -5px;
}
.reset-pass .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: block;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff !important;
}
@media screen and (min-width: 0) and (max-width: 1366px) {
  .home-page .login-box .login-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  .home-page .login-box .login-content .login-form {
    margin-top: 25px;
    margin-left: 25px;
    width: 18.875rem;
    height: 110%;
    background: rgba(0, 64, 125, 0.5);
    float: left;
  }
  .home-page .login-box .login-content .login-form .user-message {
    padding-top: 8.3rem;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie1 {
    margin-left: 14% !important;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie2 {
    margin-left: 14% !important;
  }
  .home-page .login-box .login-content .nav-list {
    margin-left: 18%;
    margin-top: -68%;
  }
  .home-page .login-box .login-content .nav-list .list1-title {
    font-size: 22px;
    margin-bottom: 1.67rem;
  }
  .home-page .login-box .login-content .nav-list .list1 {
    width: 55%;
    display: flex;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 {
    width: 350px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 .title {
    margin-top: 222px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1:hover:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: #000;
    opacity: 0.2;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 {
    width: 200px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 i {
    margin-left: 78px;
    margin-top: 88px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 .title {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 {
    width: 171px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 i {
    margin-top: 48px;
    margin-left: 65.6px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 {
    width: 169px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 i {
    margin-top: 48px;
    margin-left: 60.8px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 {
    width: 200px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 .title {
    margin-top: 147.2px;
  }
  .home-page .login-box .login-content .nav-list .list2-title {
    margin-top: -520px;
    margin-left: 635px;
    font-size: 22px;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    display: inline-block;
  }
  .home-page .login-box .login-content .nav-list .list2 {
    margin-left: 635px;
    margin-top: 28px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item1 {
    width: 230px;
    height: 262.5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i {
    margin-top: 8%;
    margin-left: 36%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 i {
    margin-top: 10%;
    margin-left: 35%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 .title {
    margin-top: 0px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item4 {
    margin-top: 272px;
    width: 230px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item5 {
    margin-top: 273px;
    margin-left: 69.2%;
    width: 220px;
    height: 187px;
    left: 0;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    cursor: pointer;
    position: absolute;
    margin-left: 1450px;
    margin-top: -30px;
  }
  .home-page .login-box .login-content .nav-list .module-manager i {
    display: inline-block;
    background: url('../../image/login/gl.png') no-repeat center;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .home-page .login-box .login-content .nav-list .module-manager .module-text {
    color: #00e8fb;
    font-size: 16px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .home-page {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box .login-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  .home-page .login-box .login-content .login-form {
    width: 18%;
    height: 110%;
  }
  .home-page .login-box .login-content .login-form .user-message {
    padding-top: 5%;
  }
  .home-page .login-box .login-content .login-form .user-message .user-avatar img {
    width: 80px;
    height: 80px;
    background-size: 100% 100%;
  }
  .home-page .login-box .login-content .login-form .user-message .login-title {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .longtime {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie1 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie2 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .divider {
    width: 200px !important;
    margin-left: 17% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn {
    bottom: 20%;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
    margin-left: 15%;
  }
  .home-page .login-box .login-content .nav-list {
    margin-left: 18%;
    margin-top: -52%;
  }
  .home-page .login-box .login-content .nav-list .list1-title {
    margin-left: 4%;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    margin-left: 1003px;
  }
  .home-page .login-box .login-content .nav-list .list1 {
    width: 48%;
    display: flex;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 {
    width: 350px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 .title {
    margin-top: 222px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 {
    width: 200px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 i {
    margin-left: 78px;
    margin-top: 88px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 {
    width: 171px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 i {
    margin-top: 48px;
    margin-left: 65.6px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 {
    width: 169px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 i {
    margin-top: 48px;
    margin-left: 60.8px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 {
    width: 200px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 .title {
    margin-top: 147.2px;
  }
  .home-page .login-box .login-content .nav-list .list2-title {
    margin-top: -520px;
    margin-left: 635px;
    font-size: 22px;
  }
  .home-page .login-box .login-content .nav-list .list2 {
    margin-left: 635px;
    margin-top: 28px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 {
    width: 230px;
    height: 262.5px;
    position: absolute;
    background: url('../../image/login/4.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 40%;
    margin-left: 34%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/5.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/6.png') no-repeat center;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 {
    position: absolute;
    margin-top: 273px;
    width: 230px;
    height: 187px;
    background: url('../../image/login/7.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 {
    position: absolute;
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
    background: url('../../image/login/8.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item1 {
    width: 230px;
    height: 262.5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i {
    margin-top: 8%;
    margin-left: 36%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i.menu-oplog {
    margin-top: 6%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 .title {
    margin-top: 5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 i.menu-oplog {
    margin-top: 4%;
    margin-left: 32%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 .title {
    margin-top: 0px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item4 {
    margin-top: 273px;
    width: 230px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item5 {
    width: 220px;
    height: 187px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .home-page {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box .login-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  .home-page .login-box .login-content .login-form {
    width: 18%;
    height: 78%;
  }
  .home-page .login-box .login-content .login-form .user-message {
    padding-top: 10%;
  }
  .home-page .login-box .login-content .login-form .user-message .user-avatar img {
    width: 80px;
    height: 80px;
    background-size: 100% 100%;
  }
  .home-page .login-box .login-content .login-form .user-message .login-title {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .longtime {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie1 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie2 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .divider {
    width: 200px !important;
    margin-left: 17% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn {
    bottom: 23%;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
    margin-left: 15%;
  }
  .home-page .login-box .login-content .nav-list {
    margin-left: 18%;
    margin-top: -50%;
  }
  .home-page .login-box .login-content .nav-list .list1-title {
    margin-left: 4%;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    margin-left: 1003px;
  }
  .home-page .login-box .login-content .nav-list .list1 {
    width: 48%;
    display: flex;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 {
    width: 350px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 .title {
    margin-top: 222px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 {
    width: 200px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 i {
    margin-left: 78px;
    margin-top: 88px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 {
    width: 171px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 i {
    margin-top: 48px;
    margin-left: 65.6px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 {
    width: 169px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 i {
    margin-top: 48px;
    margin-left: 60.8px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 {
    width: 200px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 .title {
    margin-top: 147.2px;
  }
  .home-page .login-box .login-content .nav-list .list2-title {
    margin-top: -510px;
    margin-left: 635px;
    font-size: 22px;
  }
  .home-page .login-box .login-content .nav-list .list2 {
    margin-left: 635px;
    margin-top: 16px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 {
    width: 230px;
    height: 262.5px;
    position: absolute;
    background: url('../../image/login/4.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 40%;
    margin-left: 34%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/5.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/6.png') no-repeat center;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 {
    position: absolute;
    margin-top: 273px;
    width: 230px;
    height: 187px;
    background: url('../../image/login/7.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 {
    position: absolute;
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
    background: url('../../image/login/8.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item1 {
    width: 230px;
    height: 262.5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i {
    margin-top: 8%;
    margin-left: 36%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i.menu-oplog {
    margin-top: 6%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 .title {
    margin-top: 5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 i.menu-oplog {
    margin-top: 4%;
    margin-left: 32%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 .title {
    margin-top: 0px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item4 {
    margin-top: 273px;
    width: 230px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item5 {
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .home-page {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box .login-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  .home-page .login-box .login-content .login-form {
    width: 18%;
    height: 95%;
  }
  .home-page .login-box .login-content .login-form .user-message {
    padding-top: 10%;
  }
  .home-page .login-box .login-content .login-form .user-message .user-avatar img {
    width: 80px;
    height: 80px;
    background-size: 100% 100%;
  }
  .home-page .login-box .login-content .login-form .user-message .login-title {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .longtime {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie1 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie2 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .divider {
    width: 200px !important;
    margin-left: 17% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn {
    bottom: 24%;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
    margin-left: 15%;
  }
  .home-page .login-box .login-content .nav-list {
    margin-left: 18%;
    margin-top: -50%;
  }
  .home-page .login-box .login-content .nav-list .list1-title {
    margin-left: 4%;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    margin-left: 1003px;
  }
  .home-page .login-box .login-content .nav-list .list1 {
    width: 51%;
    display: flex;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 {
    width: 350px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 .title {
    margin-top: 222px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 {
    width: 200px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 i {
    margin-left: 78px;
    margin-top: 88px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 {
    width: 171px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 i {
    margin-top: 48px;
    margin-left: 65.6px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 {
    width: 169px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 i {
    margin-top: 48px;
    margin-left: 60.8px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 {
    width: 200px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 .title {
    margin-top: 147.2px;
  }
  .home-page .login-box .login-content .nav-list .list2-title {
    margin-top: -510px;
    margin-left: 645px;
    font-size: 22px;
  }
  .home-page .login-box .login-content .nav-list .list2 {
    margin-left: 645px;
    margin-top: 16px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 {
    width: 230px;
    height: 262.5px;
    position: absolute;
    background: url('../../image/login/4.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 40%;
    margin-left: 34%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/5.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/6.png') no-repeat center;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 {
    position: absolute;
    margin-top: 273px;
    width: 230px;
    height: 187px;
    background: url('../../image/login/7.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 {
    position: absolute;
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
    background: url('../../image/login/8.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item1 {
    width: 230px;
    height: 262.5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i {
    margin-top: 8%;
    margin-left: 36%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i.menu-oplog {
    margin-top: 6%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 .title {
    margin-top: 5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 i.menu-oplog {
    margin-top: 4%;
    margin-left: 32%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 .title {
    margin-top: 0px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item4 {
    margin-top: 273px;
    width: 230px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item5 {
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .home-page {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box {
    width: 100%;
    height: 100%;
  }
  .home-page .login-box .login-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
  .home-page .login-box .login-content .login-form {
    width: 18%;
    height: 98%;
  }
  .home-page .login-box .login-content .login-form .user-message {
    padding-top: 10%;
  }
  .home-page .login-box .login-content .login-form .user-message .user-avatar img {
    width: 80px;
    height: 80px;
    background-size: 100% 100%;
  }
  .home-page .login-box .login-content .login-form .user-message .login-title {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .longtime {
    margin-top: 4px;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie1 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data #pitPie2 {
    width: 160px !important;
    height: 160px !important;
    margin-left: 25% !important;
    padding-top: 0% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .divider {
    width: 240px !important;
    margin-left: 17% !important;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn {
    bottom: 25%;
  }
  .home-page .login-box .login-content .login-form .device-data .logout-btn .exit-btn {
    margin-left: 15%;
  }
  .home-page .login-box .login-content .nav-list {
    margin-left: 18%;
    margin-top: -45%;
  }
  .home-page .login-box .login-content .nav-list .list1-title {
    margin-left: 4%;
  }
  .home-page .login-box .login-content .nav-list .module-manager {
    margin-left: 1040px;
  }
  .home-page .login-box .login-content .nav-list .list1 {
    width: 48%;
    display: flex;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 {
    width: 350px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item1 .title {
    margin-top: 222px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 {
    width: 200px;
    height: 262px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item2 i {
    margin-left: 78px;
    margin-top: 88px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 {
    width: 171px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item3 i {
    margin-top: 48px;
    margin-left: 65.6px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 {
    width: 169px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item4 i {
    margin-top: 48px;
    margin-left: 60.8px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 {
    width: 200px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list1 .item5 .title {
    margin-top: 147.2px;
  }
  .home-page .login-box .login-content .nav-list .list2-title {
    margin-top: -510px;
    margin-left: 675px;
    font-size: 22px;
  }
  .home-page .login-box .login-content .nav-list .list2 {
    margin-left: 675px;
    margin-top: 16px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 {
    width: 230px;
    height: 262.5px;
    position: absolute;
    background: url('../../image/login/4.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part1 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 40%;
    margin-left: 34%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/5.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part2 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    position: absolute;
    background: url('../../image/login/6.png') no-repeat center;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .part3 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 10%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 {
    position: absolute;
    margin-top: 273px;
    width: 230px;
    height: 187px;
    background: url('../../image/login/7.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part4 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 {
    position: absolute;
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
    background: url('../../image/login/8.png') no-repeat center;
  }
  .home-page .login-box .login-content .nav-list .list2 .part5 i {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../../image/login/tianjia.png') no-repeat;
    margin-top: 22%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item1 {
    width: 230px;
    height: 262.5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i {
    margin-top: 8%;
    margin-left: 36%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 i.menu-oplog {
    margin-top: 6%;
    margin-left: 33%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item2 .title {
    margin-top: 5px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 {
    width: 220px;
    height: 127.5px;
    margin-left: 240px;
    margin-top: 135px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 i.menu-oplog {
    margin-top: 4%;
    margin-left: 32%;
  }
  .home-page .login-box .login-content .nav-list .list2 .item3 .title {
    margin-top: 0px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item4 {
    margin-top: 273px;
    width: 230px;
    height: 187px;
  }
  .home-page .login-box .login-content .nav-list .list2 .item5 {
    margin-top: 273px;
    margin-left: 240px;
    width: 220px;
    height: 187px;
  }
}
/*
@media screen and (min-width: 1680px) and (max-width : 1920px){
    .home-page{
        width: 100%;
        height: 100%;
        .login-box{
            width: 100%;
            height: 100%;
            .login-content{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                position: relative;
                .login-form{
                    width: 18%;
                    height: 98%;
                    .user-message{
                        padding-top: 10%;
                        .user-avatar{
                            img{
                                width: 80px;
                                height: 80px;
                                background-size: 100% 100%;
                            }
                        }
                        .login-title{
                            margin-top: 0.25rem;
                        }
                    }
                    .longtime{
                        margin-top: 0.25rem;
                    }
                    
                    .device-data{
                        #pitPie1{
                            width: 160px !important;
                            height: 160px !important;
                            margin-left: 25% !important;
                            padding-top: 0% !important;
                        }
                        #pitPie2{
                            width: 160px !important;
                            height: 160px !important;
                            margin-left: 25% !important;
                            padding-top: 0% !important;
                        }
                        .divider{
                            width: 15rem !important;
                            margin-left: 17% !important;
                        }
                        .logout-btn{
                            bottom: 25%;
                            .exit-btn{
                                margin-left: 15%;
                            }
                        }
                    }
                }
                .nav-list{
                    float: left;
                    position: relative;
                    margin-left: 18%;
                    margin-top: -53rem;
                    .list1-title{   
                        color: #fff;
                        font-size: 24px;
                        font-family:Microsoft YaHei;
                        font-weight:400;
                        border-left: 4px solid rgba(0,232,251,1);
                        margin-left: 50px;
                        margin-top: 100px;
                        padding-left: 20px;
                        margin-bottom: 15px;
                    }
                    .list1{
                        width: 56%;
                        display: -webkit-flex;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        height: 50%;
                        margin-left: 50px;
                        .item1{
                            cursor: pointer;
                            position: relative;
                            width: 480px;
                            height: 350px;
                            background: url('../../image/login/gis.png') no-repeat;
                            color: #fff;
                            .title{
                                height: 40px;
                                background:rgba(4,24,53,0.8);
                                line-height: 40px;
                                padding-left: 20px;
                                margin-top: 310px;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item2{
                            position: relative;
                            cursor: pointer;
                            margin-left: 10px;
                            width: 280px;
                            background: url('../../image/login/1.png') no-repeat;
                            color: #fff;
                            i{
                                display: block;
                                width: 64px;
                                height: 52px;
                                background: url('../../image/login/jkgl\ .png') no-repeat center;
                                margin-left: 108px;
                                margin-top: 130px;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item3{
                            position: relative;
                            cursor: pointer;
                            margin-top: 10px;
                            width: 235px;
                            height: 250px;
                            flex-wrap: wrap;
                            color: #fff;
                            background: url('../../image/login/1.png') no-repeat;
                            i{
                            width: 50px;
                            height: 62px; 
                            display: block;
                            background: url('../../image/login/wjgl.png') no-repeat center;
                            margin-top: 80px;
                            margin-left: 90px;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item4{
                            position: relative;
                            cursor: pointer;
                            margin-top: 10px;
                            margin-left: 10px;
                            width: 235px;
                            height: 250px;
                            background: url('../../image/login/3.png') no-repeat;
                            color: #fff;
                            i{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/gjjl.png') no-repeat center;
                                margin-top: 80px;
                                margin-left: 90px;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item5{
                            position: relative;
                            cursor: pointer;
                            margin-top: 10px;
                            margin-left: 10px;
                            width: 280px;
                            height: 250px;
                            background: url('../../image/login/tongji.png') no-repeat;
                            color: #fff;
                            .title{
                                height: 40px;
                                background:rgba(4,24,53,0.8);
                                line-height: 40px;
                                padding-left: 20px;
                                margin-top: 210px;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .list2-title{
                        color: #fff;
                        font-size: 24px;
                        font-family:Microsoft YaHei;
                        font-weight:400;
                        border-left: 4px solid rgba(0,232,251,1);
                        margin-top: -670px;
                        margin-left: 920px;
                        padding-left: 20px;
                    }
                    .module-manager{
                        display: inline-block;
                    }
                    .list2{
                        width: 630px;
                        display: -webkit-flex;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-left: 920px;
                        margin-top: 20px;
                        .part1{
                            width: 310px;
                            height: 350px;
                            position: absolute;
                            background: url('../../image/login/4.png') no-repeat center;
                            i{
                                display: block;
                                width: 80px;
                                height: 80px;
                                background: url('../../image/login/tianjia.png') no-repeat;
                                margin-top: 40%;
                                margin-left: 36%;
                            }
                        }
                        .part2{
                            width: 310px;
                            height: 170px;
                            margin-left: 320px;
                            position: absolute;
                            background: url('../../image/login/5.png') no-repeat center;
                            i{
                                display: block;
                                width: 80px;
                                height: 80px;
                                background: url('../../image/login/tianjia.png') no-repeat;
                                margin-top: 18%;
                                margin-left: 36%;
                            }
                        }
                        .part3{
                            width: 310px;
                            height: 170px;
                            position: absolute;
                            background: url('../../image/login/6.png') no-repeat center;
                            margin-left: 320px;
                            margin-top: 180px;
                            i{
                                display: block;
                                width: 80px;
                                height: 80px;
                                background: url('../../image/login/tianjia.png') no-repeat;
                                margin-top: 18%;
                                margin-left: 36%;
                            }
                        }
                        .part4{
                        position: absolute;
                        width: 310px;
                        height: 250px;
                        background: url('../../image/login/7.png') no-repeat center;
                        margin-top: 360px; 
                        i{
                            display: block;
                            width: 80px;
                            height: 80px;
                            background: url('../../image/login/tianjia.png') no-repeat;
                            margin-top: 30%;
                            margin-left: 36%;
                        }
                        }
                        .part5{
                            position: absolute;
                            width: 310px;
                            height: 250px;
                            background: url('../../image/login/8.png') no-repeat center;
                            margin-left: 320px;
                            margin-top: 360px;
                            i{
                                display: block;
                                width: 80px;
                                height: 80px;
                                background: url('../../image/login/tianjia.png') no-repeat;
                                margin-top: 30%;
                                margin-left: 36%;
                            }
                        }
                        .title{
                            color: #fff
                        }
                    
                        .item1{
                            position: absolute;   
                            width: 310px;
                            height: 350px;
                            background: url('../../image/login/4.png') no-repeat center;
                            float: left;
                            i.menu-dept{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/bmgl.png') no-repeat center;
                                margin-top: 40%;
                                margin-left: 40%;
                            }
                            i.menu-das{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/caijizhan.png') no-repeat center;
                                margin-top: 40%;
                                margin-left: 40%;
                            }
                            i.menu-group{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/zdgl.png') no-repeat center;
                                margin-top: 40%;
                                margin-left: 40%;
                            }
                            i.menu-dev{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/thzgl.png') no-repeat center;
                                margin-top: 40%;
                                margin-left: 40%;
                            }
                            i.menu-oplog{
                                width: 84px;
                                height: 80px;
                                display: block;
                                background: url('../../image/login/wjgl.png') no-repeat center;
                                margin-top: 40%;
                                margin-left: 38%;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            .remove-icon{
                                z-index: 9999;
                                display: block;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: -2%;
                                right: -2%;
                                background: url('../../image/login/shanchu.png') no-repeat center;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item2{
                            position: absolute;
                            margin-left: 320px;
                            width: 310px;
                            height: 170px;
                            background: url('../../image/login/5.png') no-repeat center;
                            float: left;
                            i.menu-dept{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/bmgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-das{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/caijizhan.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-group{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/zdgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-dev{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/thzgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-oplog{
                                width: 84px;
                                height: 80px;
                                display: block;
                                background: url('../../image/login/wjgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            .remove-icon{
                                z-index: 9999;
                                display: block;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: -2%;
                                right: -2%;
                                background: url('../../image/login/shanchu.png') no-repeat center;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item3{
                            position: absolute;
                            margin-left: 320px;
                            margin-top: 180px;
                            width: 310px;
                            height: 170px;
                            background: url('../../image/login/6.png') no-repeat center;
                            i.menu-dept{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/bmgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-das{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/caijizhan.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-group{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/zdgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-dev{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/thzgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 40%;
                            }
                            i.menu-oplog{
                                width: 84px;
                                height: 80px;
                                display: block;
                                background: url('../../image/login/wjgl.png') no-repeat center;
                                margin-top: 12%;
                                margin-left: 37%;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 10px;
                            }
                            .remove-icon{
                                z-index: 9999;
                                display: block;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: -2%;
                                right: -2%;
                                background: url('../../image/login/shanchu.png') no-repeat center;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item4{
                            position: absolute;
                            margin-top: 360px;
                            width: 310px;
                            height: 250px;
                            background: url('../../image/login/7.png') no-repeat center;
                            i.menu-dept{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/bmgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 40%;
                            }
                            i.menu-das{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/caijizhan.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 40%;
                            }
                            i.menu-group{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/zdgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 40%;
                            }
                            i.menu-dev{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/thzgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 40%;
                            }
                            i.menu-oplog{
                                width: 84px;
                                height: 80px;
                                display: block;
                                background: url('../../image/login/wjgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 40%;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            .remove-icon{
                                z-index: 9999;
                                display: block;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: -2%;
                                right: -2%;
                                background: url('../../image/login/shanchu.png') no-repeat center;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .item5{
                            position: absolute;
                            margin-top: 360px;
                            margin-left: 320px;
                            width: 310px;
                            height: 250px;
                            background: url('../../image/login/8.png') no-repeat center;
                            i.menu-dept{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/bmgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 38%;
                            }
                            i.menu-das{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/caijizhan.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 38%;
                            }
                            i.menu-group{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/zdgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 38%;
                            }
                            i.menu-dev{
                                width: 63px;
                                height: 61px;
                                display: block;
                                background: url('../../image/login/thzgl.png') no-repeat center;
                                margin-top: 20%;
                                margin-left: 38%;
                            }
                            i.menu-oplog{
                                width: 84px;
                                height: 80px;
                                display: block;
                                background: url('../../image/login/wjgl.png') no-repeat center;
                                margin-top: 16%;
                                margin-left: 35%;
                            }
                            .title{
                                text-align: center;
                                font-size: 16px;
                                margin-top: 20px;
                            }
                            .remove-icon{
                                z-index: 9999;
                                display: block;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: -2%;
                                right: -2%;
                                background: url('../../image/login/shanchu.png') no-repeat center;
                            }
                            &:hover{   
                                &:after{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    content: "";
                                    background-color: #000;
                                    opacity: 0.2;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .add-1{
                            position: relative;  
                            display: block;
                            width: 310px;
                            height: 350px;
                            background: url('../../image/login/4.png') no-repeat center;
                        }
                    }
                    .module-manager{
                        cursor: pointer;
                        position: absolute;
                        margin-left: 1450px;
                        margin-top: -30px;
                        i{
                        display: inline-block;
                        background: url('../../image/login/gl.png') no-repeat center;
                            width: 20px;
                            height: 20px;
                            vertical-align: middle;
                        }
                        .module-text{
                            color:rgba(0,232,251,1);
                            font-size: 16px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
} 


@media screen and (min-width: 0px) and (max-width : 1366px) {
    .home-page{
        width: 100%;
        height: 100%;
        .login-box{
            .login-content {
                overflow-y: auto;
                .login-form {
                    height: 120%;
                    .device-data {
                        .logout-btn {
                           
                        }
                    }
                }
                .nav-list{
                    margin-left: 22%;
                    margin-top: -51%;
                }
            }
        }
    }
}*/
