@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
#root {
  background: url('../../image/login/bg1.png') no-repeat;
}
.wrap {
  height: 100%;
}
.ant-menu-item .left {
  float: left;
  width: 37%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.left {
  float: left;
  width: 37%;
}
.cancelModal {
  position: absolute;
  visibility: inherit;
  top: -6px;
  left: -5px;
  width: 372px;
  height: 210px;
  z-index: -1;
  border: medium;
  -webkit-filter: opacity(0);
  filter: opacity(0);
}
.right {
  float: right;
}
.delConfirm {
  /*
  .ant-modal-confirm-body .ant-modal-confirm-title {
      color: @main-white-color;
  }
  .ant-modal-confirm-btns {
      float: left;
      margin-right: 1px !important;
  }
      .ant-btn {
          background: url(../../image/video-img/anniu.png) no-repeat !important;
          border: none;
          width: 110px;
          color: @main-color;
      }

      .ant-modal-confirm-body > .anticon{
          display: block;
          width: 29px;
          height: 29px;
          background: url(../../image/jingshi.png) no-repeat;

      }
      .anticon {
          svg{
              display: none;
          }
      }
      */
}
.delConfirm .ant-modal-content {
  height: 160px;
  background: url(../../image/popm.png) no-repeat;
  padding-top: 27px;
  padding-left: 20px;
  background-size: 100% 100%;
}
.delConfirm .ant-modal-content .ant-modal-header {
  background: url(../../image/jingshi.png) no-repeat 8% 50% !important;
  border-bottom: none;
}
.delConfirm .ant-modal-content .ant-modal-header .ant-modal-title {
  margin-left: 55px;
  margin-top: 0px;
  border-bottom: none;
  color: #fff;
  font-size: 18px;
}
.delConfirm .ant-modal-content .ant-modal-body {
  padding: 0px;
  margin-right: 22px;
}
.delConfirm .ant-modal-content .ant-modal-footer {
  border-top: none;
  float: left;
  margin-left: 30px;
}
.delConfirm .ant-modal-content .ant-modal-footer .ant-btn {
  margin-right: 35px;
}
.delConfirm .ant-modal-content .ant-modal-close {
  display: none;
}
.alertOk {
  width: 315px;
  height: 156px;
  background: #032547 url(../../image/video-img/chengong.png) no-repeat center 20%;
  position: absolute;
  top: 10%;
  left: 39%;
}
.alertOk span {
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 20%;
  left: 40%;
}
.ant-popover-arrow {
  display: none;
}
.left-nav {
  position: absolute;
  top: 94px;
  left: 4px;
}
.left-nav li {
  margin-top: 4px;
  width: 40px;
  font-size: 16px;
  color: #6EF9FF;
  height: 100px;
  background: url(../../image/video-img/tab-off.png) no-repeat;
}
.left-nav li:first-child {
  background: url(../../image/video-img/tab-first.png) no-repeat;
}
.left-nav li:first-child:hover,
.left-nav li:first-child:active {
  background: url(../../image/video-img/tab-first-on.png) no-repeat !important;
}
.left-nav li:first-child .sel {
  background: url(../../image/video-img/tab-first-on.png) no-repeat !important;
}
.left-nav li a {
  color: #6EF9FF;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-rl;
  text-align: center;
  line-height: 32px;
  margin: 0 auto;
  height: 100px;
  width: 35px;
}
.left-nav li:hover,
.left-nav li:active {
  color: #ffffff;
  background: url(../../image/video-img/tab-p.png) no-repeat;
}
.left-nav li:hover a,
.left-nav li:active a {
  color: #ffffff;
}
.left-nav li .sel {
  width: 35px;
  color: #ffffff;
  background: url(../../image/video-img/tab-p.png) no-repeat;
}
.left-nav li .sel a {
  color: #ffffff;
}
/*内层滚动槽样式*/
::-webkit-scrollbar-track-piece {
  background-color: #05213d;
}
::-webkit-scrollbar {
  width: 10px;
}
/*滚动的滑块*/
::-webkit-scrollbar-thumb {
  background: #053251;
  border-radius: 30px;
}
.zTreeVideo {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 82%;
}
.zTreeVideo ul {
  position: relative;
}
.hole-wrap {
  height: 100%;
}
.hole-wrap .wrap {
  height: 100%;
}
.zhua-config .ant-modal-content {
  height: 200px !important;
}
.zhua-config .ant-modal-content .ant-modal-header {
  padding: 20px 40px;
}
.zhua-config .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center !important;
  padding-top: 20px !important;
}
.zhua-config .ant-modal-content .ant-modal-body {
  background: none !important;
  margin: 0;
}
.zhua-config .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../../image/guanbi.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -5px;
  margin-right: -5px;
}
.zhua-config .ant-modal-content .ant-modal-close:hover {
  background: url('../../image/guanbi1.png') no-repeat;
}
.zhua-config .ant-modal-content .ant-modal-close:active {
  background: url('../../image/guanbi2.png') no-repeat;
}
.zhua-config .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: none;
}
.add-collect .ant-modal-content {
  width: 360px;
  height: 200px;
  margin-left: 40%;
  margin-top: 30%;
  background: url('../../image/login/kuang.png') no-repeat;
  background-size: 100% 100%;
}
.add-collect .ant-modal-content .ant-modal-header {
  background: transparent !important;
  border-bottom: none !important;
}
.add-collect .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff !important;
  font-size: 18px;
}
.add-collect .ant-modal-content .ant-modal-body {
  background: rgba(2, 36, 70, 0.7) !important;
  margin: 0 22px 10px 22px;
}
.add-collect .ant-modal-content .ant-modal-body .ant-form .ant-form-item label {
  color: #00C1DE;
  font-size: 16px;
}
.add-collect .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner {
  background: transparent;
  border: 1px solid #00C1C6;
}
.add-collect .ant-modal-content .ant-modal-body .ant-form .ant-form-item label .ant-radio-inner::after {
  background-color: #00C1C6;
}
.add-collect .ant-modal-content .ant-modal-footer {
  border-top: none !important;
  padding: 0 !important;
}
.add-collect .ant-modal-content .ant-modal-footer .ant-btn {
  background: url('../../image/login/anniu.png') no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 45px;
}
.add-collect .ant-modal-content .ant-modal-footer .ant-btn span {
  color: #7DFAFF;
}
.add-collect .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: url('../../image/guanbi.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -5px;
  margin-right: -5px;
}
.add-collect .ant-modal-content .ant-modal-close:hover {
  background: url('../../image/guanbi1.png') no-repeat;
}
.add-collect .ant-modal-content .ant-modal-close:active {
  background: url('../../image/guanbi2.png') no-repeat;
}
.add-collect .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: none;
}
.header-wrap .header-left {
  cursor: pointer;
}
@media screen and (min-width: 1402px) {
  .terminal-wrap,
  .collect-wrap,
  .history-wrap,
  .list-wrap,
  .patrol-wrap {
    margin-top: 1.2rem;
    background: url(../../image/video-img/kuang.png) no-repeat;
    background-size: 100% 100%;
    height: 89%;
    float: left;
    width: 18%;
  }
  .terminal-wrap .input-search,
  .collect-wrap .input-search,
  .history-wrap .input-search,
  .list-wrap .input-search,
  .patrol-wrap .input-search {
    width: 82%;
    margin: 0 0 10px 14%;
    height: 40px;
    outline: none;
  }
  .terminal-wrap .input-search .ant-input,
  .collect-wrap .input-search .ant-input,
  .history-wrap .input-search .ant-input,
  .list-wrap .input-search .ant-input,
  .patrol-wrap .input-search .ant-input {
    background-color: #032344;
    border: 1px solid #064E66;
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input::-webkit-input-placeholder,
  .collect-wrap .input-search .ant-input::-webkit-input-placeholder,
  .history-wrap .input-search .ant-input::-webkit-input-placeholder,
  .list-wrap .input-search .ant-input::-webkit-input-placeholder,
  .patrol-wrap .input-search .ant-input::-webkit-input-placeholder {
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input:-ms-input-placeholder,
  .collect-wrap .input-search .ant-input:-ms-input-placeholder,
  .history-wrap .input-search .ant-input:-ms-input-placeholder,
  .list-wrap .input-search .ant-input:-ms-input-placeholder,
  .patrol-wrap .input-search .ant-input:-ms-input-placeholder {
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input::placeholder,
  .collect-wrap .input-search .ant-input::placeholder,
  .history-wrap .input-search .ant-input::placeholder,
  .list-wrap .input-search .ant-input::placeholder,
  .patrol-wrap .input-search .ant-input::placeholder {
    color: #057797;
  }
  .terminal-wrap .ant-input-suffix,
  .collect-wrap .ant-input-suffix,
  .history-wrap .ant-input-suffix,
  .list-wrap .ant-input-suffix,
  .patrol-wrap .ant-input-suffix {
    display: none;
  }
  .terminal-wrap .play-test,
  .collect-wrap .play-test,
  .history-wrap .play-test,
  .list-wrap .play-test,
  .patrol-wrap .play-test {
    position: absolute;
    width: 40px;
    height: 36px;
    border: none;
    right: 2px;
    background: url(../../image/video-img/yunyan1.png) no-repeat 10px;
  }
  .terminal-wrap .play-test:hover,
  .collect-wrap .play-test:hover,
  .history-wrap .play-test:hover,
  .list-wrap .play-test:hover,
  .patrol-wrap .play-test:hover {
    background-color: #034E67;
  }
  .terminal-wrap .bofang,
  .collect-wrap .bofang,
  .history-wrap .bofang,
  .list-wrap .bofang,
  .patrol-wrap .bofang {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('../../image/video-img/yunyan.png') no-repeat 30%;
  }
  .terminal-wrap .search-box li .bofang-btn .bofang,
  .collect-wrap .search-box li .bofang-btn .bofang,
  .history-wrap .search-box li .bofang-btn .bofang,
  .list-wrap .search-box li .bofang-btn .bofang,
  .patrol-wrap .search-box li .bofang-btn .bofang {
    margin-top: -17px;
  }
  .terminal-wrap .stop-monitor,
  .collect-wrap .stop-monitor,
  .history-wrap .stop-monitor,
  .list-wrap .stop-monitor,
  .patrol-wrap .stop-monitor {
    position: relative;
    width: 40px;
    height: 40px;
    border: none;
    float: right;
    margin-top: 9px;
    background: url(../../image/video-img/4-e.png) no-repeat;
  }
  .terminal-wrap .more-operate,
  .collect-wrap .more-operate,
  .history-wrap .more-operate,
  .list-wrap .more-operate,
  .patrol-wrap .more-operate {
    position: absolute;
    width: 40px;
    height: 36px;
    border: none;
    right: 42px;
    background: url(../../image/video-img/collecta.png) no-repeat 10px;
  }
  .terminal-wrap .more-operate:hover,
  .collect-wrap .more-operate:hover,
  .history-wrap .more-operate:hover,
  .list-wrap .more-operate:hover,
  .patrol-wrap .more-operate:hover {
    background-color: #03657E;
  }
  .terminal-wrap .collect-operate,
  .collect-wrap .collect-operate,
  .history-wrap .collect-operate,
  .list-wrap .collect-operate,
  .patrol-wrap .collect-operate {
    position: relative;
    width: 40px;
    height: 36px;
    border: none;
    float: right;
    background: url(../../image/video-img/collecta.png) no-repeat 10px;
  }
  .terminal-wrap .collect-operate:hover,
  .collect-wrap .collect-operate:hover,
  .history-wrap .collect-operate:hover,
  .list-wrap .collect-operate:hover,
  .patrol-wrap .collect-operate:hover {
    background-color: #03657E;
  }
  .terminal-wrap .collect-play,
  .collect-wrap .collect-play,
  .history-wrap .collect-play,
  .list-wrap .collect-play,
  .patrol-wrap .collect-play {
    position: relative;
    width: 40px;
    height: 36px;
    border: none;
    float: right;
    background: url(../../image/video-img/yunyan1.png) no-repeat 10px;
  }
  .terminal-wrap .collect-play:hover,
  .collect-wrap .collect-play:hover,
  .history-wrap .collect-play:hover,
  .list-wrap .collect-play:hover,
  .patrol-wrap .collect-play:hover {
    background-color: #034E67;
  }
  .terminal-wrap .top-menu-wrap {
    width: auto;
    height: 3rem;
    margin: 1rem 0 1rem 0;
  }
  .terminal-wrap .top-menu-wrap .open-close-menu {
    background: url(../../image/terminal/openIcon.png) no-repeat;
    width: 19px;
    height: 13px;
    outline: none;
    border: none;
    float: right;
    margin-right: 5%;
    margin-top: 1rem;
    cursor: pointer;
  }
  .terminal-wrap .top-menu-wrap .open-close-menu:hover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  .terminal-wrap .top-menu-wrap .patrol {
    background: url(../../image/video-img/anniu.png) no-repeat;
    color: #6EF9FF;
    width: 110px;
    height: 30px;
    outline: none;
    border: none;
    float: right;
    margin-right: 5%;
    margin-top: 0.5rem;
  }
  .terminal-wrap .top-menu-wrap .patrol:hover {
    background: url(../../image/video-img/xuanfu.png) no-repeat;
  }
  .terminal-wrap .top-menu-wrap .patrol:active {
    color: #ffffff;
    background: url(../../image/video-img/dianji.png) no-repeat;
  }
  .video-wrap {
    position: absolute;
    top: 85px;
    width: 80%;
    right: 20px;
    bottom: 0;
    background: url(../../image/video-img/video-bg.png) no-repeat;
    background-size: 100% 98%;
    overflow-y: hidden;
  }
  .video-wrap .ocx-video {
    width: 95%;
    margin: 5% 2.5%;
    height: 770px;
  }
  .video-wrap .ocx-video .video-area {
    width: 100%;
    height: 100%;
  }
  .video-wrap .screen-control {
    position: absolute;
    top: 15px;
    left: 35px;
    width: 30%;
  }
  .video-wrap .screen-control button {
    position: relative;
    width: 33px;
    height: 33px;
    background-color: transparent;
    border: 1px solid #02CAE2;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: top;
  }
  .video-wrap .screen-control .model-1 {
    background: url(../../image/video-img/1-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-1.sel {
    background: url(../../image/video-img/1-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-4 {
    background: url(../../image/video-img/4-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-4.sel {
    background: url(../../image/video-img/4-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-9 {
    background: url(../../image/video-img/9-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-9.sel {
    background: url(../../image/video-img/9-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-16 {
    background: url(../../image/video-img/16-e.png) no-repeat 4px 4px;
  }
  .video-wrap .screen-control .model-16.sel {
    background: url(../../image/video-img/16-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .btn-list {
    margin: 20px 40px 0px 0px;
  }
  .video-wrap .btn-list .btn-item {
    width: 110px;
    height: 30px;
    background: url(../../image/video-img/anniu.png) no-repeat;
    margin-left: 15px;
    color: #6EF9FF;
    border: none;
  }
  .video-wrap .btn-list .btn-item:hover {
    background: url(../../image/video-img/xuanfu.png) no-repeat;
  }
  .video-wrap .btn-list .btn-item:active {
    color: #ffffff;
    background: url(../../image/video-img/dianji.png) no-repeat;
  }
  .video-wrap .btn-list .btn-item img {
    padding-right: 5px;
  }
  .video-wrap .btn-list .all-screen {
    width: 31px;
    height: 31px;
    border: none;
    vertical-align: bottom;
    margin-left: 15px;
    background: url(../../image/video-img/all-screen.png) no-repeat;
  }
  .video-wrap .video-main {
    border: 1px solid #066F85;
    margin-top: 18px;
  }
  .video-wrap .video-main li {
    border: 1px solid #066F85;
  }
  .video-wrap .video-main li:hover,
  .video-wrap .video-main li:active {
    border: 2px solid #00ffff;
  }
  .video-wrap .video-main .sel {
    border: 2px solid #00ffff;
  }
  .video-wrap .video-main .video-operation {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
  }
  .video-wrap .video-main .video-operation .devName {
    padding-left: 10px;
    opacity: 1;
    color: #fff;
    line-height: 40px;
  }
  .video-wrap .video-main .video-operation .btn-stop {
    float: right;
    padding-right: 20px;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 2%;
    background: url(../../image/video-img/close.png) no-repeat;
    cursor: pointer;
  }
  .video-wrap .video-main .video-operation .btn-stop:hover {
    background: url(../../image/video-img/closee.png) no-repeat;
  }
  .video-wrap .video-main .video-operation .btn-stop:active {
    background: url(../../image/video-img/close.png) no-repeat;
  }
  .video-wrap .video-main .video-operation .video-quality {
    float: right;
    line-height: 40px;
    text-align: center;
  }
  .video-wrap .video-main .video-operation .video-quality input {
    cursor: pointer;
    width: 80px;
    background: rgba(10, 45, 79, 0);
    color: #00FFFF;
    border: none;
    outline: none;
    font-size: 16px;
    text-align: center;
    position: absolute;
    right: 120px;
  }
  .video-wrap .video-main .video-operation .video-quality input:hover {
    border: none;
    outline: none;
  }
  .video-wrap .video-main .video-operation .video-quality input:active {
    border: none;
    outline: none;
  }
  .video-wrap .video-main .video-operation .video-quality .video-ul {
    position: absolute;
    width: 80px;
    background: #000;
    opacity: 0.7;
    top: -160px;
    right: 120px;
    font-size: 16px;
  }
  .video-wrap .video-main .video-operation .video-quality .video-ul .on {
    color: #00FFFF;
    background: #104043;
    opacity: 0.8;
  }
  .video-wrap .video-main .video-operation .video-quality .video-ul li {
    border: none;
    cursor: pointer;
    text-align: center;
    color: #ffffff;
  }
}
.collect-wrap .top {
  margin: 20px 20px 0 14%;
}
.collect-wrap .top .collect-name {
  font-size: 16px;
  color: #a9f8ff;
}
.collect-wrap .top .add-collect {
  background: url(../../image/video-img/anniu.png) no-repeat;
  color: #6EF9FF;
  width: 110px;
  height: 30px;
  outline: none;
  border: none;
  float: right;
  margin-right: 5%;
}
.collect-wrap .top .add-collect:hover {
  background: url(../../image/video-img/xuanfu.png) no-repeat;
}
.collect-wrap .top .add-collect:active {
  color: #ffffff;
  background: url(../../image/video-img/dianji.png) no-repeat;
}
.collect-wrap .input-search {
  margin-top: 15px !important;
}
.collect-wrap .del-collect {
  position: absolute;
  width: 40px;
  height: 40px;
  border: none;
  left: 12%;
  margin-top: 9px;
  background: url(../../image/video-img/shanchu.png) no-repeat;
}
.collect-wrap .edit-collect {
  position: absolute;
  width: 40px;
  height: 40px;
  border: none;
  left: 14%;
  margin-top: 9px;
  background: url(../../image/video-img/bianji.png) no-repeat;
}
.history-list {
  margin-left: 15%;
  margin-top: 5%;
  height: 100%;
}
.history-list .total {
  font-size: 16px;
  color: #a9f8ff;
}
.history-list .remove-list {
  background: url(../../image/video-img/anniu.png) no-repeat;
  color: #6EF9FF;
  width: 110px;
  height: 30px;
  outline: none;
  border: none;
  float: right;
  margin-right: 5%;
}
.history-list .remove-list:hover {
  background: url(../../image/video-img/xuanfu.png) no-repeat;
}
.history-list .remove-list:active {
  color: #ffffff;
  background: url(../../image/video-img/dianji.png) no-repeat;
}
.history-list .input-search {
  margin: 15px 0 10px 0;
}
.history-list .ant-menu {
  background: none;
  color: #a9f8ff;
  max-height: 80%;
  overflow-y: auto;
  width: 98%;
}
.history-list .ant-menu .ant-menu-item {
  position: relative;
  height: 75px;
  width: 98%;
  border-bottom: 1px solid #074962;
  padding: 0 5px;
}
.history-list .ant-menu .ant-menu-item:hover,
.history-list .ant-menu .ant-menu-item:active {
  background: #043551;
  color: #00ffff;
}
.history-list .ant-menu .ant-menu-item .monitor-name {
  display: block;
}
.history-list .ant-menu .ant-menu-item-selected {
  background: #043551;
  color: #00ffff;
}
.history-list .ant-menu-vertical {
  border-right: none;
}
.history-list .play-list-icon {
  background: url(../../image/video-img/yunyan1.png) no-repeat;
  position: absolute;
  top: 10px;
  right: 50px;
  width: 18px;
  height: 20px;
}
.history-list .collect-icon {
  background: url(../../image/video-img/collecta.png) no-repeat;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 18px;
  height: 20px;
}
.history-list .all-collect {
  background: url(../../image/video-img/collect.png) no-repeat;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 18px;
  height: 20px;
}
.play-list {
  margin-left: 13%;
  margin-top: 5%;
}
.play-list .ant-menu {
  background: none;
  color: #a9f8ff;
  max-height: 760px;
  overflow-y: auto;
  width: 98%;
}
.play-list .ant-menu .ant-menu-item {
  position: relative;
  height: 75px;
  width: 98%;
  border-bottom: 1px solid #074962;
  padding: 0 5px;
}
.play-list .ant-menu .ant-menu-item:hover,
.play-list .ant-menu .ant-menu-item:active {
  background: #043551;
  color: #00ffff;
}
.play-list .ant-menu .ant-menu-item .monitor-name {
  display: block;
}
.play-list .ant-menu .ant-menu-item-selected {
  background: #043551;
  color: #00ffff;
}
.play-list .ant-menu-vertical {
  border-right: none;
}
.patrol-wrap .top {
  margin: 20px 20px 0 14%;
}
.patrol-wrap .top .collect-name {
  font-size: 16px;
  color: #a9f8ff;
}
.patrol-wrap .top .stop-patrol {
  background: url(../../image/video-img/anniu.png) no-repeat;
  color: #6EF9FF;
  width: 110px;
  height: 30px;
  outline: none;
  border: none;
  float: right;
  margin-right: 5%;
}
.patrol-wrap .top .stop-patrol:hover {
  background: url(../../image/video-img/xuanfu.png) no-repeat;
}
.patrol-wrap .top .stop-patrol:active {
  color: #ffffff;
  background: url(../../image/video-img/dianji.png) no-repeat;
}
.patrol-wrap .patrol-wrap_list-warp {
  width: 80%;
  margin-top: 15%;
  margin-left: 15%;
}
.patrol-wrap .patrol-wrap_list-warp .ant-list-item-meta-title,
.patrol-wrap .patrol-wrap_list-warp .ant-list-item-meta-description {
  color: #a9f8ff;
}
@media screen and (max-width: 1400px) {
  .header-main {
    background-size: 97% !important;
    font-size: 28px !important;
    line-height: 38px !important;
  }
  .terminal-wrap,
  .collect-wrap,
  .history-wrap,
  .list-wrap {
    margin-top: 1.2rem;
    background: url(../../image/video-img/kuang.png) no-repeat;
    background-size: 100% 100%;
    height: 89%;
    float: left;
    width: 23%;
  }
  .terminal-wrap .input-search,
  .collect-wrap .input-search,
  .history-wrap .input-search,
  .list-wrap .input-search {
    width: 82%;
    margin: 0 0 10px 14%;
    height: 40px;
    outline: none;
  }
  .terminal-wrap .input-search .ant-input,
  .collect-wrap .input-search .ant-input,
  .history-wrap .input-search .ant-input,
  .list-wrap .input-search .ant-input {
    background-color: #032344;
    border: 1px solid #064E66;
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input::-webkit-input-placeholder,
  .collect-wrap .input-search .ant-input::-webkit-input-placeholder,
  .history-wrap .input-search .ant-input::-webkit-input-placeholder,
  .list-wrap .input-search .ant-input::-webkit-input-placeholder {
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input:-ms-input-placeholder,
  .collect-wrap .input-search .ant-input:-ms-input-placeholder,
  .history-wrap .input-search .ant-input:-ms-input-placeholder,
  .list-wrap .input-search .ant-input:-ms-input-placeholder {
    color: #057797;
  }
  .terminal-wrap .input-search .ant-input::placeholder,
  .collect-wrap .input-search .ant-input::placeholder,
  .history-wrap .input-search .ant-input::placeholder,
  .list-wrap .input-search .ant-input::placeholder {
    color: #057797;
  }
  .terminal-wrap .ant-input-suffix,
  .collect-wrap .ant-input-suffix,
  .history-wrap .ant-input-suffix,
  .list-wrap .ant-input-suffix {
    display: none;
  }
  .terminal-wrap .play-test,
  .collect-wrap .play-test,
  .history-wrap .play-test,
  .list-wrap .play-test {
    position: relative;
    width: 40px;
    height: 36px;
    border: none;
    float: right;
    background: url(../../image/video-img/yunyan1.png) no-repeat 10px;
  }
  .terminal-wrap .collect-play,
  .collect-wrap .collect-play,
  .history-wrap .collect-play,
  .list-wrap .collect-play {
    position: relative;
    width: 40px;
    height: 40px;
    border: none;
    float: right;
    margin-top: 9px;
    background: url(../../image/video-img/yunyan1.png) no-repeat;
  }
  .terminal-wrap .stop-monitor,
  .collect-wrap .stop-monitor,
  .history-wrap .stop-monitor,
  .list-wrap .stop-monitor {
    position: relative;
    width: 40px;
    height: 40px;
    border: none;
    float: right;
    margin-top: 9px;
    background: url(../../image/video-img/4-e.png) no-repeat;
  }
  .terminal-wrap .more-operate,
  .collect-wrap .more-operate,
  .history-wrap .more-operate,
  .list-wrap .more-operate {
    position: absolute;
    width: 40px;
    height: 36px;
    border: none;
    right: 62px;
    background: url(../../image/video-img/collecta.png) no-repeat;
  }
  .terminal-wrap .collect-operate,
  .collect-wrap .collect-operate,
  .history-wrap .collect-operate,
  .list-wrap .collect-operate {
    position: relative;
    width: 40px;
    height: 40px;
    border: none;
    float: right;
    margin-top: 9px;
    background: url(../../image/video-img/collecta.png) no-repeat;
  }
  .terminal-wrap .patrol {
    background: url(../../image/video-img/anniu.png) no-repeat;
    color: #6EF9FF;
    width: 110px;
    height: 30px;
    outline: none;
    border: none;
    float: right;
    margin-right: 5%;
    margin-top: 6%;
  }
  .terminal-wrap .patrol:hover {
    background: url(../../image/video-img/xuanfu.png) no-repeat;
  }
  .terminal-wrap .patrol:active {
    color: #ffffff;
    background: url(../../image/video-img/dianji.png) no-repeat;
  }
  .video-wrap {
    position: absolute;
    top: 85px;
    width: 74%;
    right: 20px;
    bottom: 0;
    background: url(../../image/video-img/video-bg.png) no-repeat;
    background-size: 100% 98%;
    overflow-y: hidden;
  }
  .video-wrap .ocx-video {
    width: 95%;
    margin: 5% 2.5%;
    height: 770px;
  }
  .video-wrap .ocx-video .video-area {
    width: 100%;
    height: 100%;
  }
  .video-wrap .screen-control {
    position: absolute;
    top: 15px;
    left: 35px;
    width: 30%;
  }
  .video-wrap .screen-control button {
    position: relative;
    width: 33px;
    height: 33px;
    background-color: transparent;
    border: 1px solid #02CAE2;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: top;
  }
  .video-wrap .screen-control .model-1 {
    background: url(../../image/video-img/1-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-1.sel {
    background: url(../../image/video-img/1-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-4 {
    background: url(../../image/video-img/4-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-4.sel {
    background: url(../../image/video-img/4-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-9 {
    background: url(../../image/video-img/9-e.png) no-repeat 4px 4px;
    border-right: none;
  }
  .video-wrap .screen-control .model-9.sel {
    background: url(../../image/video-img/9-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .screen-control .model-16 {
    background: url(../../image/video-img/16-e.png) no-repeat 4px 4px;
  }
  .video-wrap .screen-control .model-16.sel {
    background: url(../../image/video-img/16-p.png) no-repeat 4px 4px;
    background-color: #0ED0D3;
  }
  .video-wrap .btn-list {
    margin: 20px 40px 0px 0px;
  }
  .video-wrap .btn-list .btn-item {
    width: 110px;
    height: 30px;
    background: url(../../image/video-img/anniu.png) no-repeat;
    margin-left: 15px;
    color: #6EF9FF;
    border: none;
  }
  .video-wrap .btn-list .btn-item:hover {
    background: url(../../image/video-img/xuanfu.png) no-repeat;
  }
  .video-wrap .btn-list .btn-item:active {
    color: #ffffff;
    background: url(../../image/video-img/dianji.png) no-repeat;
  }
  .video-wrap .btn-list .btn-item img {
    padding-right: 5px;
  }
  .video-wrap .btn-list .all-screen {
    width: 31px;
    height: 31px;
    border: none;
    vertical-align: bottom;
    margin-left: 15px;
    background: url(../../image/video-img/all-screen.png) no-repeat;
  }
  .video-wrap .video-main {
    border: 1px solid #066F85;
    margin-top: 18px;
  }
  .video-wrap .video-main li {
    border: 1px solid #066F85;
  }
  .video-wrap .video-main li:hover,
  .video-wrap .video-main li:active {
    border: 2px solid #00ffff;
  }
  .video-wrap .video-main .sel {
    border: 2px solid #00ffff;
  }
  .history-list .input-search {
    margin: 15px 0px 10px 2% !important;
  }
}
