@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
.dept-wrap {
  min-width: 1366px;
  width: 100%;
  height: 100%;
}
.content-wrap {
  margin-top: 1.2rem;
  width: 100%;
  height: 100%;
}
.ant-modal-content {
  background: url("../../image/login/kuang.png") no-repeat;
  background-size: 100% 100%;
}
.ant-modal-content .ant-modal-body {
  margin: 0 22px 10px 22px;
}
.ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  color: white;
}
.ant-modal-content .ant-btn {
  background: url("../../image/login/anniu.png") no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
}
.ant-modal-content .ant-btn span {
  color: #7dfaff;
}
.edit-gather .ant-upload {
  background: rgba(14, 30, 44, 0.5);
  color: #6ef9ff;
}
.edit-gather .ant-upload svg {
  color: #6ef9ff;
}
.edit-gather .ant-upload .ant-upload-text {
  color: #6ef9ff !important;
}
.edit-gather button {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6ef9ff;
}
.edit-gather button:hover {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  box-shadow: 0 0 5px #bbf6fb;
}
.edit-gather button:active {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
}
.edit-gather button:focus {
  color: #ffffff;
  background: url(../../image/video-img/dianji.png) no-repeat;
}
.edit-gather .notice {
  color: red;
}
.edit-gather label {
  color: #fff;
}
.edit-gather .ant-modal-content .ant-modal-header {
  background: transparent;
  border-bottom: none;
}
.edit-gather .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 1.1rem;
}
.edit-gather .ant-modal-content .ant-modal-body {
  background: #022446;
}
.edit-gather .ant-modal-content .ant-modal-footer {
  border-top: none;
}
.edit-gather .ant-modal-content .ant-form-item-control .ant-input {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6ef9ff;
}
.edit-gather .ant-modal-content .ant-form-item-control .ant-select-selection {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6ef9ff;
}
.edit-gather .ant-modal-content .ant-form-item-control .ant-select-selection .ant-select-arrow {
  color: #6ef9ff;
}
.edit-gather .ant-modal-content .ant-form-item-control .ant-form-explain {
  position: absolute;
}
.edit-gather .ant-modal-content .ant-form-item-control .ant-form-item-children i.select-btn {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url("../../image/add.png") no-repeat;
}
.edit-gather .ant-modal-content .ant-form-item-with-help {
  margin-bottom: 24px;
}
.edit-gather .ant-modal-content .ant-modal-close {
  width: 36px;
  height: 36px;
  background: #002446;
  border: 1px solid #45fcfc;
  border-radius: 50%;
  margin-top: -5px;
  margin-right: -5px;
}
.edit-gather .ant-modal-content .ant-modal-close .ant-modal-close-x {
  display: block;
  margin-top: -10px;
  margin-left: -10px;
  color: #fff;
}
.deptList-wrap {
  width: 17%;
  height: 100%;
  float: left;
  margin-left: 1%;
  background: url(../../image/video-img/kuang.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.deptList-wrap .menu-tree {
  list-style: none;
  padding: 30px 20px;
}
.deptList-wrap .menu-tree .menu-title {
  color: #a9f8ff;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 10px;
  overflow: hidden;
  font-size: 1rem;
  line-height: 40px;
  text-overflow: ellipsis;
  cursor: pointer;
}
.deptList-wrap .menu-tree .menu-title span {
  margin-left: 12px;
}
.deptList-wrap .menu-tree .menu-title .port {
  margin-right: 0.85rem;
  float: right;
  font-size: 0.85rem;
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .dept-tree {
  max-height: 450px;
  overflow-y: auto;
}
.deptList-wrap .menu-tree .ant-tree {
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .ant-tree li {
  font-size: 0.92rem;
}
.deptList-wrap .menu-tree .ant-tree li .ant-tree-title .anticon-delete {
  margin-left: 0.6rem;
}
.deptList-wrap .menu-tree .ant-tree li .ant-tree-title .anticon-edit {
  margin-left: 1rem;
}
.deptList-wrap .menu-tree .ant-tree li .ant-tree-node-content-wrapper {
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .ant-tree li span.ant-tree-iconEle {
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .ant-tree li .ant-tree-node-selected::before {
  color: #a9f8ff;
  background: rgba(0, 193, 222, 0.14) !important;
}
.deptList-wrap .menu-tree .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.deptList-wrap .menu-tree .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: rgba(0, 193, 222, 0.14);
}
.deptList-wrap .menu-tree .dept-title {
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .menu-title-checked {
  color: #a9f8ff;
  background: rgba(0, 193, 222, 0.14);
}
.deptList-wrap .menu-tree .menu-title:hover {
  color: #a9f8ff;
  background: rgba(0, 193, 222, 0.14);
}
.deptList-wrap .menu-tree .submenu-group {
  padding: 0 1.37rem;
  max-height: 450px;
  overflow-y: auto;
}
.deptList-wrap .menu-tree .submenu-group .group-title {
  padding: 5px 0 0 1.2rem;
  font-size: 0.92rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deptList-wrap .menu-tree .submenu-group .checked-group-title {
  padding: 5px 0 0 1.2rem;
  font-size: 0.92rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #a9f8ff;
  background: rgba(0, 193, 222, 0.14);
}
.deptList-wrap .menu-tree .submenu-group .limit-menu {
  display: inline-block;
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a9f8ff;
}
.deptList-wrap .menu-tree .submenu-group .group-title:hover {
  background: rgba(0, 193, 222, 0.14);
}
.deptList-wrap .menu-tree .submenu-group .anticon-delete,
.deptList-wrap .menu-tree .submenu-group .anticon-edit {
  color: #a9f8ff;
  margin-right: 0.6rem;
  float: right;
  line-height: 30px;
}
.deptList-wrap .menu-tree .updown {
  float: right;
  line-height: 40px;
  font-size: 12px;
}
.data-wrap {
  width: 80%;
  height: 100%;
  float: right;
  margin-right: 1%;
  background: url(../../image/video-img/video-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dept-table {
  padding: 25px 20px;
}
.dept-table input.ant-input {
  width: 12.5rem !important;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6ef9ff;
}
.dept-table input::-webkit-input-placeholder {
  color: #057797;
}
.dept-table button {
  width: auto;
  margin-left: 10px;
  background: url(../../image/video-img/anniu.png);
  background-size: 100% 100%;
  border: none;
  color: #a9f8ff;
}
.dept-table button:hover {
  background: url(../../image/video-img/xuanfu.png) no-repeat;
  background-size: 100% 100%;
  color: #6ef9ff;
}
.dept-table button:active {
  color: #6ef9ff;
  background: url(../../image/video-img/anniu.png) no-repeat;
  background-size: 100% 100%;
}
.dept-table button:focus {
  color: #6ef9ff;
  background: url(../../image/video-img/anniu.png) no-repeat;
  background-size: 100% 100%;
}
.dept-table .ant-row .ant-select .ant-select-selection {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6ef9ff;
}
.dept-table .ant-row .ant-select .ant-select-selection .ant-select-arrow {
  z-index: 999999;
  color: #6ef9ff;
}
.dept-table .ant-row .ant-select .ant-select-selection__placeholder {
  color: #066f85;
}
.ant-upload-list-item-info {
  color: #4caaad;
}
.anticon-paper-clip {
  color: #4caaad !important;
}
.ant-select-tree-title,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  color: #6ef9ff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: rgba(0, 193, 222, 0.14);
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: rgba(0, 193, 222, 0.14);
}
.content {
  margin-top: 10px;
}
.content .ant-table-content {
  font-size: 1rem !important;
  height: 100% !important;
}
.content .ant-table-content .ant-table-fixed-left table {
  background: rgba(0, 0, 0, 0);
}
.content .ant-table-content .ant-table-thead tr th {
  background: rgba(0, 193, 222, 0.14);
  text-align: center !important;
  border-bottom: none !important;
  padding: 1rem 16px;
  color: #00c1de;
}
.content .ant-table-content .ant-table-thead tr th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #00c4c8;
}
.content .ant-table-content .ant-table-thead tr th span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.content .ant-table-content .ant-table-thead .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #05213c;
  border-top: 0;
  border-left: 0;
}
.content .ant-table-content .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.content .ant-table-content .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.content .ant-table-content .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.content .ant-table-content .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(0, 193, 222, 0.14);
}
.content .ant-table-content tbody td {
  border: none !important;
  padding: 1.1rem 0px !important;
  color: #00c1de;
}
.content .ant-table-content tbody td .ant-checkbox-wrapper .ant-checkbox span.ant-checkbox-inner {
  border: 1px solid #00c1de;
  background: rgba(0, 193, 222, 0.14);
}
.content .ant-table-content tbody td .ant-checkbox-wrapper-checked .ant-checkbox-checked span.ant-checkbox-inner {
  border: 1px solid #00c1de;
  background: #00c4c8;
}
.content .ant-table-content tbody td .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #05213c;
  border-top: 0;
  border-left: 0;
}
.content .ant-table-content tbody td a.edit i {
  display: inline-block;
  width: 18px;
  height: 16px;
  background: url(../../image/bianji.png) no-repeat !important;
}
.content .ant-table-content tbody td a.delete i {
  display: inline-block;
  width: 18px;
  height: 16px;
  background: url(../../image/iconfontshanchu.png) no-repeat !important;
}
.content .ant-table-content .ant-table-tbody tr {
  line-height: 0;
}
.content .ant-table-content .ant-table-tbody tr.even-bg {
  background: #05213c !important;
}
.content .ant-table-content .ant-table-tbody tr.odd-bg {
  background: #082b4a !important;
}
.content .ant-table-content .ant-table-tbody tr.ant-table-row.ant-table-row-selected td {
  background: url("../../image/rowStyle.png") 87% 100%;
}
.content .ant-table-wrapper .ant-pagination {
  float: left !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-prev {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  background: #082a4a !important;
  border: 1px solid #00c1de !important;
  border-radius: 0px !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-prev svg {
  vertical-align: 0.3em !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-prev {
  margin-right: 5px !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-prev a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00c1de;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-item {
  height: 28px;
  background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(70, 255, 255, 0.5);
  border-radius: 1px;
  float: left;
  margin-left: 4px;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-item:hover {
  border: none;
  background: #00c4c8;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-item a {
  color: #6ef9ff;
  line-height: 28px;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: #00c4c8 !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-next {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  border-radius: 0px !important;
  background: #082a4a !important;
  border: 1px solid #00c1de !important;
  margin-left: 7px !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-next a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00c1de !important;
}
.content .ant-table-wrapper .ant-pagination .ant-pagination-next a svg {
  vertical-align: 0.3em !important;
}
.ant-table-placeholder {
  background: rgba(0, 193, 222, 0.14);
  border: none;
}
.ant-empty-description {
  color: #a9f8ff;
}
