.gisheader {
  width: 100%;
  position: absolute;
}
.gisheader .header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 63px;
}
.gisheader .back {
  width: 8%;
  height: 40px;
  line-height: 35px;
  background: url(../image/gis/back.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 10px;
  left: 21px;
  cursor: pointer;
}
.gisheader .back .title {
  color: #a9f8ff;
  line-height: 30px;
  padding-left: 38%;
  font-size: 14px !important;
}
.gisheader .head-title {
  width: 41%;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #a9f8ff;
  padding-left: 29%;
  padding-top: 10px;
  /*select {
        width: 40%;
        background: none;
        text-align: center;
        border: none;
        outline: none;

        option {
          width: 20%;
          height: 120px;
          background: #0e3151;
          border: none;
          outline: none;
        }
      }*/
}
.gisheader .head-title .select-value {
  width: 40%;
  margin: 0 auto;
  background: none;
  text-align: center;
  position: relative;
}
.gisheader .head-title .select-value .select-tri {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 26px;
  height: 21px;
  background: url("../image/tri.png") no-repeat center center;
  background-size: 26px 21px;
}
.gisheader .head-title .select-value .select-box {
  position: absolute;
  right: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  top: 45px;
  font-size: 24px;
  width: 187px;
  height: 236px;
  background: rgba(2, 36, 70, 0.9);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.gisheader .head-title .select-value .select-box .select-item {
  width: 100%;
  margin: 0 auto;
  text-indent: 20px;
  text-align: left;
}
.gisheader .head-title .select-value .select-box .select-item:hover {
  background: rgba(0, 234, 255, 0.1);
  transition: all 0.4s;
}
.gisheader :before,
.gisheader :after,
.gisheader * {
  box-sizing: unset !important;
}
.gisheader .count-down {
  position: absolute;
  right: 19px;
  width: 246px;
  color: #ffffff;
  height: 40px;
  line-height: 35px;
  background: url(../image/gis/top-right.png) no-repeat;
  background-size: 100% 100%;
  top: 10px;
}
.gisheader .top-head {
  position: absolute;
  height: 100%;
  line-height: 45px;
  width: 100%;
  background: url(../image/gis/top-head1.png) no-repeat 50%;
  display: flex;
  text-align: center;
}
.gisheader .date-time {
  width: 100%;
  text-align: center;
  position: absolute;
}
.header-wrap .select-value {
  width: 18%;
  margin: 0 auto;
  background: none;
  text-align: center;
  position: relative;
}
.header-wrap .select-value .select-tri {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 26px;
  height: 21px;
  background: url("../image/tri.png") no-repeat center center;
  background-size: 26px 21px;
}
.header-wrap .select-value .select-box {
  position: absolute;
  z-index: 2;
  right: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  top: 60px;
  font-size: 24px;
  width: 187px;
  height: 236px;
  background: rgba(2, 36, 70, 0.9);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.header-wrap .select-value .select-box .select-item {
  line-height: 45px;
  letter-spacing: auto;
  font-size: 24px;
  width: 100%;
  margin: 0 auto;
  text-indent: 20px;
  text-align: left;
}
.header-wrap .select-value .select-box .select-item:hover {
  background: rgba(0, 234, 255, 0.1);
  transition: all 0.4s;
}
