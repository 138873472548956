@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.box {
  background: #041D36;
  height: 100%;
}
.box .police-main-content {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  background: url('../image/body-bg.png') no-repeat;
  background-size: 100% 100% !important;
  height: 90%;
  border-radius: 15px;
}
.box .police-main-content .content-top {
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: -10px;
}
.box .police-main-content .content-top .ant-form {
  display: inline;
}
.box .police-main-content .content-top .ant-form .ant-row {
  display: inline-block;
  margin-left: -2%;
}
.box .police-main-content .content-top .ant-form .ant-row span {
  color: #00C1DE;
}
.box .police-main-content .content-top .ant-form .ant-row span.time-ranger {
  position: absolute;
  color: #00C1DE;
  left: 8%;
  line-height: 38px;
}
.box .police-main-content .content-top .ant-form .ant-row .ant-calendar-picker {
  margin-left: 20%;
}
.box .police-main-content .content-top .ant-form .ant-row .ant-calendar-picker .ant-input {
  width: 240px;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
}
.box .police-main-content .content-top .ant-form .ant-row .ant-calendar-picker .ant-input input::-webkit-input-placeholder {
  color: #057797;
}
.box .police-main-content .content-top .ant-form .ant-row #deptId {
  width: 60% !important;
}
.box .police-main-content .content-top .ant-form .ant-row span.name {
  margin-left: 20px;
}
.box .police-main-content .content-top .ant-form .ant-row span.dept {
  margin-left: 40px;
}
.box .police-main-content .content-top .ant-form .ant-row span.first-word {
  margin-left: 0%;
}
.box .police-main-content .content-top .ant-form .ant-row input.ant-input {
  width: 200px;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.box .police-main-content .content-top .ant-form .ant-row input::-webkit-input-placeholder {
  color: #057797;
}
.box .police-main-content .content-top .ant-form .ant-row .ant-select .ant-select-selection {
  width: 200px;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.box .police-main-content .content-top .ant-form .ant-row span.last-word {
  margin-left: 60px;
}
.box .police-main-content .content-top .btn-group {
  display: inline-block;
  margin-top: 4px;
  margin-left: 1%;
}
.box .police-main-content .content-top .btn-group .ant-btn {
  background: url('../image/btn.png');
  width: 110px;
  height: 32px;
  margin-left: 1px;
  border: none;
  color: #6EF9FF;
}
.box .police-main-content .content-top .btn-group .ant-btn:hover {
  background: url('../image/xuanfu.png');
  background-size: 100% 100%;
}
.box .police-main-content .content-top .btn-group .ant-btn:active {
  background: url('../image/dianji.png');
  background-size: 100% 100%;
  color: #fff;
}
.box .police-main-content .content-top .btn-group button.ant-btn.reset-btn {
  margin-right: 20px;
  margin-left: 20px;
}
.box .police-main-content .content-top .btn-group button.ant-btn.delete-btn {
  height: 32px;
  background: url('../image/del_nor.png') no-repeat;
  background-size: 100% 100%;
  color: #F24235;
}
.box .police-main-content .content-top .btn-group button.ant-btn.delete-btn:hover {
  background: url('../image/del_sel.png') no-repeat;
  background-size: 100% 100%;
}
.box .police-main-content .content-top .btn-group button.ant-btn.delete-btn:active {
  background: url('../image/del_pre.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.box .police-main-content .content-top ul.ant-menu {
  display: inline-block;
  margin-left: 75%;
  vertical-align: middle;
  height: 30px;
  border-right: none;
  margin-top: 3px;
}
.box .police-main-content .content-top ul.ant-menu .ant-menu-item {
  display: inline-block;
  width: 50px;
  height: 30px;
  padding: 0;
  text-align: center;
  line-height: 30px;
  margin-top: 0;
  vertical-align: top;
  background: #0A2D4F;
  color: #6EF9FF;
  border: 1px solid #02cae2;
}
.box .police-main-content .content-top ul.ant-menu .ant-menu-item-selected {
  background: #00c4c8;
  border: 1px solid #02cae2;
  color: #fff;
}
.box .police-main-content .divider {
  width: 97.5%;
  height: 2px;
  background: #074962;
  margin-left: 22px;
}
.box .police-main-content .btn-group-table {
  width: 97%;
  position: relative;
  margin-top: 15px;
  margin-left: 20px;
}
.box .police-main-content .btn-group-table .btn-left {
  display: inline-block;
}
.box .police-main-content .btn-group-table .btn-left a {
  display: inline-block;
  margin-left: 10px;
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: url('../image/off.png') no-repeat;
  border: none;
  color: #6EF9FF;
}
.box .police-main-content .btn-group-table .btn-left a:active {
  background: #00c4c8 !important;
  color: #fff;
}
.box .police-main-content .btn-group-table .btn-left a:visited {
  background: #00c4c8 !important;
  color: #fff;
}
.box .police-main-content .btn-group-table .btn-left a:hover {
  background: #00c4c8;
  color: #fff;
}
.box .police-main-content .btn-group-table .btn-left .yysp {
  margin-left: 10px;
  width: 100px;
  height: 30px;
  border: none;
  color: #6EF9FF;
  background-size: 100% 100%;
}
.box .police-main-content .btn-group-table .btn-right {
  display: inline-block;
  position: absolute;
  right: -10px;
}
.box .police-main-content .btn-group-table .btn-right .ant-btn {
  width: 110px;
  height: 30px;
  border: none;
  color: #6EF9FF;
}
.box .police-main-content .btn-group-table .btn-right .delete-btn {
  background: url('../image/delete-btn.png') no-repeat;
  margin-right: 10px;
  color: #F24235;
}
.box .police-main-content .btn-group-table .btn-right .download-btn {
  display: inline-block;
  width: 110px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  border: none;
  color: #6EF9FF;
  background: url('../image/download-btn.png') no-repeat;
}
.box .police-main-content .content {
  margin-top: -5px;
}
.box .police-main-content .content .ant-table-content {
  width: 100%;
  margin-left: 22px;
  margin-top: 20px;
}
.box .police-main-content .content .ant-table-content .ant-table-placeholder {
  background: transparent !important;
}
.box .police-main-content .content .ant-table-content .ant-table-placeholder .ant-empty-description {
  color: #6ef9ff;
}
.box .police-main-content .content .ant-table-content table {
  font-size: 16px !important;
  width: 100%;
}
.box .police-main-content .content .ant-table-content table thead th {
  background: rgba(0, 193, 222, 0.14);
  text-align: center !important;
}
.box .police-main-content .content .ant-table-content table thead th span.ant-table-header-column div span.ant-table-column-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00c1de;
}
.box .police-main-content .content .ant-table-content table thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00C4C8;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.box .police-main-content .content .ant-table-content table thead th span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .police-main-content .content .ant-table-content table thead th .ant-checkbox-input {
  -webkit-appearance: none !important;
}
.box .police-main-content .content .ant-table-content table .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.box .police-main-content .content .ant-table-content table tbody .ant-table-row {
  background: rgba(4, 29, 54, 0.5) !important;
  font-size: 1rem !important;
}
.box .police-main-content .content .ant-table-content table tbody td {
  border: none !important;
  text-align: center !important;
  padding: 21px 0px;
  color: #00C1DE;
  position: relative;
}
.box .police-main-content .content .ant-table-content table tbody td .ant-checkbox-wrapper .ant-checkbox span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.box .police-main-content .content .ant-table-content table tbody td .ant-checkbox-wrapper-checked .ant-checkbox-checked span.ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: #00C4C8;
}
.box .police-main-content .content .ant-table-content table tbody td .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #05213C;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.box .police-main-content .content .ant-table-content table tbody td a.recall i {
  display: inline-block;
  position: absolute;
  left: 42%;
  top: 23%;
  width: 32px;
  height: 31px;
  background: url(../image/recall.png) no-repeat !important;
}
.box .police-main-content .content .ant-table-content table tbody td tbody tr .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #05213C !important;
}
.box .police-main-content .content .ant-table-content table tbody td .ant-tag {
  border: none !important;
}
.box .police-main-content .content .ant-table-content table tbody tr {
  line-height: 0;
}
.box .police-main-content .content .ant-table-content tbody tr.even-bg {
  background: #05213C !important;
}
.box .police-main-content .content .ant-table-content tbody tr.odd-bg {
  background: #082B4A !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination {
  float: left !important;
  margin-left: 23px;
  margin-top: 20px;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-total-text {
  display: none !important;
  height: 25px !important;
  line-height: 22px !important;
  margin-left: 15px !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-prev {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
  border-radius: 0px !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-prev svg {
  vertical-align: 0.3em !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-options {
  display: none !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-jump-prev {
  float: left !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-jump-next {
  float: left !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #00C1DE;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-prev a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-item {
  height: 28px;
  background: linear-gradient(0deg, rgba(250, 252, 255, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(70, 255, 255, 0.5);
  border-radius: 1px;
  float: left;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-item:hover {
  border: none !important;
  background: #00C4C8 !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-item a {
  margin: 0 !important;
  color: #6EF9FF;
  line-height: 28px;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: #00C4C8 !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-next {
  height: 28px !important;
  line-height: 28px !important;
  float: left !important;
  width: 58px !important;
  border-radius: 0px !important;
  background: #082A4A !important;
  border: 1px solid #00c1de !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-next a {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;
  color: #00C1DE !important;
}
.box .police-main-content .content .ant-table-wrapper .ant-pagination .ant-pagination-next a svg {
  vertical-align: 0.3em !important;
}
.box .content .pit-empty {
  margin: 1% auto;
  color: rgba(0, 0, 0, 0.25);
  position: relative;
  width: 97.5%;
  height: 170px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.box .content .pit-empty .ant-empty-image {
  height: 40px;
  margin-top: 2.5%;
}
.box .content .pit-empty .ant-empty-description {
  text-align: center;
  color: #00C1DE;
}
.box .content .ant-table {
  width: 97.5% !important;
}
.box tr.ant-table-row.ant-table-row-selected td {
  background: url('../image/rowStyle.png') 87% 100% !important;
}
.ant-select-dropdown.xiala {
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background: #05213C;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.xiala ul li.ant-select-dropdown-menu-item {
  color: #00C1DE;
}
.ant-select-dropdown.xiala ul li.ant-select-dropdown-menu-item:hover {
  background: #043853;
}
.ant-select-dropdown.xiala .ant-select-dropdown-menu-item-selected {
  background: #043853;
}
.ant-popover .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #043853 !important;
  border-bottom-color: #043853 !important;
  border-left-color: transparent;
  /* -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07); */
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover .ant-popover-inner {
  background: #043853;
}
.ant-popover .ant-popover-inner .ant-popover-message {
  color: #00C1DE;
}
.ant-select-dropdown {
  width: 200px;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background: #05213C;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown ul li.ant-select-dropdown-menu-item {
  color: #00C1DE;
}
.ant-select-dropdown ul li.ant-select-dropdown-menu-item:hover {
  background: #043853;
}
.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  background: #043853;
}
.dept-xiala {
  width: 200px;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum", "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background: #05213C;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dept-xiala ul li.ant-select-dropdown-menu-item {
  color: #00C1DE;
}
.dept-xiala ul li.ant-select-dropdown-menu-item:hover {
  background: #043853;
}
.dept-xiala .ant-select-dropdown-menu-item-selected {
  background: #043853;
}
.ant-popover .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #043853 !important;
  border-bottom-color: #043853 !important;
  border-left-color: transparent;
  /* -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07); */
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover .ant-popover-inner {
  background: #043853;
}
.ant-popover .ant-popover-inner .ant-popover-message {
  color: #00C1DE;
}
.content .ant-table-wrapper .ant-table .ant-table-content .ant-table-body table tbody tr:hover > td {
  background: #043853 !important;
}
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .box .header-wrap .header-main {
    margin-left: 20px;
  }
  .box .police-main-content {
    height: 102%;
  }
  .box .police-main-content .content-top .ant-row .ant-select-selection {
    width: 150px !important;
  }
  .box .police-main-content .content-top .ant-row input.ant-input {
    width: 150px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .box .header-wrap .header-main {
    margin-left: 20px;
  }
  .box .police-main-content {
    height: 90%;
  }
  .box .police-main-content .content-top .ant-row .ant-select-selection {
    width: 150px !important;
  }
  .box .police-main-content .content-top .ant-row input.ant-input {
    width: 150px !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .box .header-wrap .header-main {
    margin-left: 20px;
  }
  .box .police-main-content {
    height: 90%;
  }
  .box .police-main-content .content-top .ant-row .ant-select-selection {
    width: 150px !important;
  }
  .box .police-main-content .content-top .ant-row input.ant-input {
    width: 150px !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .box .header-wrap .header-main {
    margin-left: 20px;
  }
  .box .police-main-content {
    height: 90%;
  }
  .box .police-main-content .content-top .ant-row .ant-select-selection {
    width: 150px !important;
  }
  .box .police-main-content .content-top .ant-row input.ant-input {
    width: 150px !important;
  }
}
