@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
.ztree {
  margin-left: 40px;
}
.ztree .dept-name span {
  color: #a9f8ff;
}
.ztree span {
  color: #848484;
  font-size: 16px;
  line-height: 40px;
}
.ztree li {
  line-height: 36px;
  padding: 3px;
}
.ztree li ul {
  padding: 0 0 0 20px;
}
.ztree li a > span:not(button) {
  max-width: 140px;
  width: 35%;
  vertical-align: middle;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-indent: 0;
}
.ztree li a {
  height: 36px;
  line-height: 36px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 15px;
  display: inline-block;
  margin-left: 10px;
}
.ztree li a:hover {
  border: none;
  height: 36px;
  line-height: 36px;
  background-color: #043551;
  text-decoration: none;
  z-index: -1;
  opacity: 0.8;
}
.ztree li a.curSelectedNode {
  border: none;
  height: 36px;
  line-height: 36px;
  background-color: #043551;
  z-index: -1;
}
.ztree li a.idle .node_name {
  color: #a9f8ff !important;
}
.ztree li a.working .node_name {
  color: #a9f8ff !important;
}
.ztree li a.offline .node_name {
  color: #848484 !important;
}
.ztree li span.button.chk {
  width: 13px;
  height: 13px;
  margin: 0 -10px 0 10px;
  cursor: auto;
  z-index: 999;
}
.ztree li span.level0.noline_open {
  width: 13px;
  height: 11px;
  background: url(../../image/video-img/zu-shouqi.png) no-repeat !important;
  z-index: 999 !important;
  position: relative;
  left: 7px;
  top: 0px !important;
}
.ztree li span.level0.noline_close {
  width: 13px;
  height: 11px;
  background: url(../../image/video-img/zu-zhankai.png) no-repeat !important;
  z-index: 999;
  position: relative;
  left: 7px;
}
.ztree li span.button.noline_open {
  width: 13px;
  height: 11px;
  background: url(../../image/video-img/shouqi.png) no-repeat;
  z-index: 999;
  position: relative;
  left: 7px;
  top: 3px;
}
.ztree li span.button.noline_close {
  width: 13px;
  height: 11px;
  background: url(../../image/video-img/zhankai.png) no-repeat;
  z-index: 9;
  position: relative;
  left: 7px;
}
.search-box {
  width: calc(100% - 45px);
  height: 40px;
  margin-left: 40px;
}
.search-box li {
  color: #00C1DE;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  position: relative;
}
.search-box li:hover {
  background-color: #043551;
  opacity: 0.8;
  height: 40px;
  line-height: 40px;
}
.search-box li:hover .oper-btn-group {
  display: inline-block;
  line-height: 40px;
}
.search-box li .oper-btn-group {
  position: absolute;
  line-height: 40px;
  right: 10px;
  display: none;
}
.search-box li .oper-btn-group .allCollect {
  background: url('../../image/video-img/collect.png') no-repeat 10px;
}
.search-box li .search-res {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.search-box li .bofang-btn {
  display: inline-block;
  margin-top: -18px;
  height: 40px;
  vertical-align: middle;
}
#treeCollect li a {
  width: 90%;
}
