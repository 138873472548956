@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
.dept-analysis-wrap {
  background: #041D36;
  min-width: 1366px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.dept-analysis-wrap .analysis-content {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  background: url('../../image/body-bg.png') no-repeat;
  background-size: 100% 100% !important;
  height: 90%;
  border-radius: 15px;
}
.dept-analysis-wrap .analysis-content .ant-btn {
  background: url("../../image/login/anniu.png") no-repeat center;
  border: none;
  outline: none;
  width: 110px;
  height: 30px;
  margin-right: 2rem;
}
.dept-analysis-wrap .analysis-content .ant-btn span {
  color: #7dfaff;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 2rem;
  padding-right: 2rem;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap {
  padding-left: 4rem;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .title {
  margin-right: 1rem;
  color: #00C1DE;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .select-company {
  width: 12.5rem;
  margin-right: 2rem;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .select-company .ant-select-selection__placeholder {
  color: #057797;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .subunits-checkbox {
  color: #00C1DE;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .subunits-checkbox .ant-checkbox-inner:after {
  position: absolute;
  border: 2px solid #0682fa;
  border-top: 0;
  border-left: 0;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .subunits-checkbox .ant-checkbox-inner {
  border: 1px solid #00c1de !important;
  background: rgba(0, 193, 222, 0.14);
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap input.ant-input {
  width: 200px;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap input::-webkit-input-placeholder {
  color: #057797;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .ant-select .ant-select-selection {
  width: 200px;
  height: 32px;
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  border-radius: 2px;
  color: #6EF9FF;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .right-wrap .ant-select .ant-select-selection .ant-select-arrow {
  color: #00C1DE;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .range-picker {
  width: 450px !important;
  padding-right: 2rem;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .range-picker .ant-calendar-picker-input.ant-input {
  background: rgba(14, 30, 44, 0.5);
  border: 1px solid #066f85;
  color: #6ef9ff;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .range-picker .ant-calendar-picker-input.ant-input input::-webkit-input-placeholder {
  color: #057797;
}
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .range-picker .ant-calendar-picker-input.ant-input .ant-calendar-range-picker-separator,
.dept-analysis-wrap .analysis-content .top-btn-menu-wrap .range-picker .ant-calendar-picker-input.ant-input .ant-calendar-picker-icon {
  color: #6ef9ff;
}
.dept-analysis-wrap .analysis-content .divider {
  width: 97.5%;
  height: 2px;
  background: #074962;
  margin-top: 2rem;
  margin-left: 22px;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap {
  padding: 0 2rem;
  width: 100%;
  height: 100%;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content {
  width: 100%;
  margin-left: 0.1rem;
  margin-top: 2rem;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content .ant-table-placeholder {
  background: transparent !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content .ant-table-placeholder .ant-empty-description {
  color: #6ef9ff;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table {
  font-size: 16px !important;
  width: 100%;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table thead th {
  background: rgba(0, 193, 222, 0.14);
  text-align: center !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table thead th span.ant-table-header-column div span.ant-table-column-title {
  font-family: Microsoft YaHei, sans-serif;
  font-weight: 400;
  color: #00c1de;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  color: #6EF9FF;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table tbody .ant-table-row {
  background: rgba(4, 29, 54, 0.5) !important;
  font-size: 1rem !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table tbody td {
  border: none !important;
  text-align: center !important;
  padding: 0.8% 0 !important;
  color: #00C1DE;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table tbody td .ant-tag {
  border: none !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content table tbody td tbody tr {
  line-height: 0;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content tbody tr.even-bg {
  background: #05213C !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-content tbody tr.odd-bg {
  background: #082B4A !important;
}
.dept-analysis-wrap .analysis-content .statistic-list-wrap .ant-table-wrapper .ant-table .ant-table-content .ant-table-body table tbody tr:hover > td {
  background: #043853 !important;
}
.hovertap {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #00103d;
  border: 1px solid #afafaf;
  line-height: 24px;
}
