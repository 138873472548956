@charset "UTF-8";
html {
  overflow-y: hidden;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  background: url('../../image/login/bg1.png') no-repeat;
  position: absolute;
  overflow-x: hidden;
}
/*头部*/
/* 按钮 */
.btnOut {
  outline: none;
  border: none;
}
/* 主题内容 */
/* 表格 */
.rank {
  display: inline-block;
}
.active {
  background: #DDE9F9;
  border-right: 2px solid #3B8CFF;
}
/*************************************video***************************************************/
/*主要字体颜色*/
/* 次要字体颜色 */
/* 可点击字体颜色 */
* {
  padding: 0;
  margin: 0;
}
body {
  min-width: 1366px;
  background: url('../../image/login/bg.png') no-repeat;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
:before,
:after,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-alert.ant-alert-warning.ant-alert-closable {
  position: absolute;
  right: 30px;
  bottom: 60px;
  z-index: 9999999;
}
@media (min-width: 0) {
  html,
  body {
    font-size: 12px;
  }
}
@media (min-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (min-width: 1900px) {
  html,
  body {
    font-size: 16px;
  }
}
.header-wrap {
  width: 100%;
  height: 55.16px;
  padding: 0 16.8px;
}
.header-wrap .sosMusic {
  display: none;
}
.header-wrap .header-left {
  float: left;
  width: 8%;
  height: 40px;
  line-height: 40px;
  background: url(../../image/video-img/header-left.png) no-repeat;
  text-align: center;
  font-size: 14px;
  color: #a9f8ff;
  cursor: pointer;
  background-size: 100% 100%;
}
.header-wrap .header-main {
  width: 84%;
  float: left;
  height: 4.2857rem;
  line-height: 4rem;
  text-align: center;
  background: url(../../image/video-img/header.png) no-repeat;
  color: #a9f8ff;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 4px;
  background-size: 100% 100%;
}
.header-wrap .header-right {
  position: relative;
}
.header-wrap .header-right .date-time {
  width: 14%;
  height: 40px;
  float: right;
  line-height: 40px;
  text-align: center;
  background: url(../../image/video-img/head-right.png) no-repeat;
  font-size: 1rem;
  color: #ffffff;
  background-size: 100% 100%;
  position: relative;
  margin-top: -3.6%;
}
.header-wrap .header-right .date-time .police-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../../image/police/police.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 8%;
  top: 27%;
}
.header-wrap .header-right .date-time .police-count {
  width: 15px;
  height: 15px;
  background: #FD3D33;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  padding: 0 4px;
  line-height: 15px;
  position: absolute;
  margin-left: -8%;
  margin-top: 3%;
}
.header-wrap .header-right .sos-wrap {
  display: none;
  width: 19%;
  background: #013F63;
  position: absolute;
  right: 0;
  margin-top: 2.5%;
  z-index: 99;
}
.header-wrap .header-right .sos-wrap::before {
  content: '';
  width: 0;
  height: 0;
  border: 10px solid;
  position: absolute;
  top: -20px;
  right: 62%;
  border-color: transparent transparent #013F63;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list {
  height: 35px;
  line-height: 35px;
  color: #A9F8FF;
  border-bottom: 1px solid #003556;
  font-size: 14px;
  cursor: pointer;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list i {
  margin-left: 4%;
  margin-right: 3%;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #EC3839;
  border-radius: 100%;
  margin-bottom: 4%;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list .sos-content {
  display: inline-block;
  width: 72%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list:hover {
  background: #005A79;
  color: #fff;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list .ignore-btn {
  display: none;
}
.header-wrap .header-right .sos-wrap .sos-ul .sos-list .ignore-btn.isShow {
  display: inline-block;
  color: #00FFFF;
  padding-left: 15px;
  vertical-align: top;
}
.header-wrap .header-right .sos-wrap.isShow {
  display: block;
}
.left-nav {
  clear: both;
}
