/* 数字字体 */
@font-face {
  font-family: myfont;
  src: url(../css/butter.ttf), url(../css/butter.eot);
}
.progress-show {
  font-size: calc(100vw / 19.2);
}
/* 颜色 */
/* 样式 */
.progress-show {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  position: relative;
}
.progress-show .title {
  font-size: 1rem;
  color: #FFF;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 0;
}
.progress-show .num {
  font-family: myfont;
  color: #00EFFF;
  font-size: 18px;
  position: absolute;
  top: 4px;
  right: 0;
}
.progress-show .progress-bar-bg {
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #2B3A5F;
  position: relative;
  margin-top: 32px;
}
.progress-show .progress-bar-bg .progress-bar {
  background-image: linear-gradient(to right, #00B2FC 30%, #FFF33B 60%);
  border-radius: 4px;
  position: absolute;
  height: 100%;
  left: 0;
}
@media screen and (max-width: 1400px) {
  .progress-show {
    height: 40px;
  }
}
